.container{
    opacity: 0.5;
    position: relative;
    width: 100%;
    display: flex;
    padding: 8px;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    max-height: 7rem;
    overflow: hidden;
    cursor: default;
    
    h2 {
        font-size: 0.85rem;
        font-weight: bolder;
        overflow-y: auto;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    h2 img {
        width: 0.85rem;
        height: auto;
        margin-right: 4px;
        margin-top: auto;
        margin-bottom: auto;
    }
    h3 {
        font-size: 0.65rem;
        font-weight: normal;
        text-align: justify;
        overflow-y: auto;
        display: -webkit-box;
        -webkit-line-clamp: 4;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    h3 span {
        font-weight: bold;
    }
    h4 {
        font-size:0.7rem;
        align-self: flex-end;
        color: var(--black-5);
    }
}

.container:hover {
    opacity: 1;
    max-height: 100%;
    h3 {
        -webkit-line-clamp: 8;
    }   
}
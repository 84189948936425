@import '../../styles/mixins/main.scss';

.policy {
  background: transparent;
  //padding: 72pt 72pt 72pt 72pt !important;
  p {
    @include text-sm;
    color: var(--black-3);
    margin: 12px 0 12px 0;
  }

  h4{
    margin: 12px 0 12px 0;
  }

  overflow: scroll;
  scrollbar-width: none;
  max-height: 70vh;
}

.policy-title {
  color: var(--black-1);
  margin: 12px 0 12px 0;
}
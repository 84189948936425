$goldenRatio: 1.61803398875;
$width: calc(192px * #{$goldenRatio});
$height: calc(#{$width} * #{$goldenRatio});

.container {
    position: relative;
    background-color: var(--bg-white);
    width: $width;
    height: $height;
    border-left: 1px solid var(--primary-color);
    // border: 1px solid var(--primary-color);
    // border-radius: 8px;
    margin: auto;
    z-index: 1;
    padding: 0.25rem;
    display: flex;
    flex-direction: column;
    justify-content:space-between;
    align-items: center;
    pointer-events: all;
    @media (max-width: 720px) {
        width: calc(100vw - 3rem);
        margin-left:0;
        max-width: $width;
    }
}

@import '../../../styles/mixins/main.scss';

.profiles-section-alignment {
  .page-alignment {
    padding: 0 0 24px 0;
    @include flex-between;

    div {
      h1 {
        @include heading-3;
        color: var(--black-3);
        margin: 0;
      }
    }

    div + div {
      img {
        @include pointer;
      }
    }
  }

  h2 {
    @include text-bold;
    color: var(--black-3);
    margin: 0 0 1rem 0;
  }

  .filter-grid {
    max-width: 100%;

    .filter-grid-items {

      .fill-button {
        padding: 50px 0 0 0;
        display: block;
      }

      a {
        display: block;
        @include text-sm;
        color: var(--black-5);
        padding: 0 0 1rem 0;
      }

      h3 {
        @include text-bold;
        margin: 0 0 24px 0;
        color: var(--black-3);
      }

      p {
        @include text-sm;
        color: var(--black-5);
        margin: 0 0 23px 0;
      }
    }
  }

  .select-option-alignment {
    padding: 0 0 24px 0;

    button {
      background-color: var(--bg-white);
      padding: 2px 12px;
      height: 40px;
      min-width: 145px;
      box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.1);
      border-radius: 4px;
      @include pointer;
      @include text-sm-bold;
      @include flex-center;
      justify-content: center;
      color: var(--black-3) !important;
      border: 1px solid var(--primary-color);

      span {
        padding-right: 1rem;
        padding-bottom: 0px !important;
        color: var(--black-3) !important;
      }
    }
  }
}

.filter-grid-border-style {
  border: 1px solid var(--border-color-1);
  border-radius: 4px;
  margin: 0 0 24px 0;
  padding: 15px;
}

.filter-grid-border-style:hover {
  box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.1);
}

.icon-text-filter-alignment {
  @include flex-between;
  width: 100%;
  padding: 0 0 24px 0;

  h2 {
    @include pointer();
    margin: 0 !important;
  }

  h3 {
    margin: 0 !important;
  }
}

.setting-icon-end-side {
  @include flex-center;
  @include pointer;

  img {
    font-size: 20px;
    color: var(--black-5);
    width: 16px;
    //max-height: 16px;

  }

  a:not(:first-child) {
    margin-left: 8px;
  }
}

.add-profile-popup {

  display: flex;
  flex-direction: column;
  justify-content: space-around;
  gap: 24px;
  width: 100%;
  //height: 400px;


  h1 {
    //@include text-md-bold;
  }

  .header-buttons {
    display: flex;
    justify-content: space-around;
    gap: 12px;

    h2 {
      @include text-md-bold;
      //border-radius: 6px;
      padding: 12px;
      //width: 50%;
    }

    h2:hover {
      border-bottom: 1px solid var(--bg-light-grey);
      cursor: pointer;
    }

    .selected {
      border-bottom: 1px solid var(--bg-light-grey);
    }
  }

  .generic-profile-form {
    //height: 300px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
  }

  .tiles {
    display: flex;
    justify-content: left;
    align-content: baseline;
    flex-wrap: wrap;
    gap: 4px;
    height: 300px;
    overflow: scroll;
    margin-top: 24px;

    div {
      height: fit-content;
      border-radius: 6px;
      padding: 12px;
      border: 1px solid var(--bg-light-grey);
      cursor: pointer;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      gap: 8px;
      align-items: center;

      img{
        height: 12px;
      }
    }

    .skeleton{
      width: 200px;
      margin: 0;
      height: 46px;
      //line-height: 1.5px;
      //height: auto;
    }

    .custom-profile{
      background-color: var(--primary-light-color);

    }

    div:hover {
      border: 1px solid black;
    }
  }
}
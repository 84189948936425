@import 'src/styles/mixins/main';


.intake-third-alignment {
    padding: 40px 0 0 0;
    .button-center-alignment {
        @include flex-center;
        @include breakpoint("max-sm") {
            padding: 30px 0 0 0;
        }
        .fill-button {
            display: block;
            margin-right: 23px;
        }
    }
    @include breakpoint("max-sm") {
        padding: 0 0 0 0;
    }
    .intake-box {
        height: calc(100vh - 128px - 79px);
        @include breakpoint("max-md") {
            height: auto;
        }
        .countries-grid {
            @include grid-layout;
            grid-template-columns: repeat(1 , 1fr);
            gap: 0 0;
            @include breakpoint("max-md") {
                grid-template-columns: repeat(1 , 1fr);
                gap: 60px;
            }
            .countries-grid-items {
                padding: map-get($pixel, sm ) * 3 + 1px;
                background: rgba(255, 255, 255, 0.6);
                mix-blend-mode: normal;
                backdrop-filter: blur(14px);
                height: calc(100vh - 128px - 110px);
                overflow: auto;
                overflow-x: hidden;
                @include breakpoint("max-sm") {
                    height: calc(100vh - 128px - 71px);
                }
                h1 {
                    margin-bottom: 50px;
                }
                .countries-sub-grid {
                    @include grid-layout;
                    grid-template-columns: repeat(12 , 1fr);
                    gap: 16px 24px;
                    @include breakpoint("max-sm") {
                        grid-template-columns: repeat(1 , 1fr);
                    }
                    .selected {
                        border: 1px solid var(--primary-color) !important;
                    }
                    .countries-sub-grid-items {
                        grid-column: span 4;
                        background-color: var(--bg-white);
                        @include normal-radius;
                        padding: map-get($pixel, sm ) * 3 + 1px;
                        border: 1px solid transparent;
                        @include pointer;
                        @include normal-shadow;
                        &:hover {
                            border: 1px solid var(--primary-color);
                        }
                        @include breakpoint("max-sm") {
                            grid-column: auto;
                        }
                        p {
                            @include text-sm;
                            margin: 0;
                            color: var(--invert-color);
                        }
                        .box-title-alignment {
                            @include flex-center;
                            padding: 0 0 map-get($pixel, sm ) * 3 + 1px 0;
                            div {
                                span {
                                    @include text;
                                    display: block;
                                    padding-left: 8px;
                                    color: var(--black-color);
                                    display: block;
                                }
                            }

                            .flag-section{

                                span{
                                    display: flex;
                                }

                                img{
                                    width: 20px;
                                    height: 15px;
                                    margin-right: .3125rem;
                                    border-radius: 2px;
                                }
                            }

                            .title-section{
                                display: flex;
                                justify-content: space-between;
                                width: 90%;
                            }
                        }
                    }
                }
            }
            .grid-items {
                .map-show {
                    @include breakpoint("max-md") {
                        background-color: #fff;
                    }
                    
                }
                h1 {
                    @include heading-2;
                    color: var(--black-color);
                    text-align: left;
                    margin: 0 0 42px 0;
                }
               
                .terms-conditions {
                    @include flex-center;
                    padding: 28px 0 40px 0;
                    label {
                        @include flex-center;
                    }
                    justify-content: map-get($layout, center );
                    div {
                        @include flex-center;
                        input[type="checkbox"] {
                            width: 18px;
                            height: 18px;
                            margin: 0;
                            padding: 0;
                        }
                        span {
                            @include text;
                            color: var(--invert-color);
                            padding-left: 8px;
                        }
                    }
                }
            }
        }
    }
}
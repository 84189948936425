@import '../../styles/mixins/main.scss';

header {
    position: absolute;
    top: 0;
    width: 100%;
    padding: map-get($pixel, md ) * 2 + 5px 0 0 0;
    @include breakpoint("max-sm") {
        position: relative;
    }
    .header-alignment {
        @include flex-between;
        .logo {
            img {
                @include pointer;
                max-width: 210px;
                @include breakpoint("max-sm") {
                    max-width: 150px;
                }
            }
        }
        .language-select {
            position: map-get($layout,  relative );
            @include flex-center;
            min-width: 118px;
            img {
                @include pointer;
                max-width: 16px;
            }
        }
    }
}

.spacer {
    flex-grow: 1;
  }

@media (max-width: 768px) {
    header {
        .header-alignment {
            .logo {
                img {
                    width: 0px;
                }
            }
        }
    }
}
.header.header.header {
    padding: 0;
    top: unset;
    position: relative;
    text-align: center;
    h1 {
        font-size: 1.5rem;
        font-weight: 700;
        font-family: 'Helvetica Neue', Arial, 'Noto Sans', sans-serif;
        color: var(--primary-color);
        margin: 0;
        padding: 0;
    }
}



@import '../../styles/mixins/main.scss';

.container{
  position: relative; /* Ensure absolute positioning works within this container */


  .text, .image {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); /* Center the elements */
  }

  .text {
    z-index: 1; /* Ensure the text is on top */
    @include text-sm-bold;
    color: var(--black-3);
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
  }
}

.circular-progress {
  --half-size: calc(var(--size) / 2);
  --radius: calc((var(--size) - var(--stroke-width)) / 2);
  --circumference: calc(var(--radius) * 3.14159 * 2);
  --dash: calc((var(--progress) * var(--circumference)) / 100);
  animation: progress-animation 0.5s ease-in-out 0s 1 forwards;
}

.circular-progress circle {
  cx: var(--half-size);
  cy: var(--half-size);
  r: var(--radius);
  stroke-width: var(--stroke-width);
  fill: none;
  stroke-linecap: round;
}

.circular-progress circle.bg {
  stroke: #ddd;
}

.circular-progress circle.fg {
  transform: rotate(-90deg);
  transform-origin: var(--half-size) var(--half-size);
  stroke-dasharray: var(--dash) calc(var(--circumference) - var(--dash));
  transition: stroke-dasharray 0.3s linear 0s;
  stroke: var(--bar-color);
}

@keyframes progress-animation {
  from {
    --progress: 0;
  }
}

@property --progress {
  syntax: "<number>";
  inherits: false;
  initial-value: 0;
}
@import '../../../styles/mixins/main.scss';

.general-settings-section {
  .profile-input {
    padding: 0 0 40px 0;
    max-width: 462px;

    label {
      @include text-bold;
      color: var(--black-3);
      padding: 0 0 24px 0;
      display: block;
    }
  }

  .company-dropdown {
    h1 {
      @include text-bold;
      color: var(--black-3);
      margin: 0 0 24px 0;
    }
  }

  .selected-companies-list {

    margin: 24px 0 0 0;

    li {
      display: flex;
      justify-content: space-between;
      gap: 8px;
      align-items: center;
      align-content: center;
      padding: 12px;
      margin: 0 0 0 12px;
      border-radius: 6px;
      width: 98%;

      span {
        p {
          @include font-normal;
          color: var(--black-3);
          align-content: center;
        }
      }
    }

    li:hover {
      background-color: var(--bg-light-grey);
    }

    .image-icon-span {
      display: flex;
      justify-content: left;
      img {
        height: 24px;
        margin-right: 12px;
      }
    }

    .image-buttons-span {
      display: flex;
      justify-content: right;
      gap: 12px;
      img {
        height: 14px;
        cursor: pointer;
      }
    }
  }

  .list-skeleton{
    margin: 24px 0 0 0;
    li {
      align-items: center;
      align-content: center;
      padding: 12px;
      margin: 0 0 0 12px;
      border-radius: 6px;
      height: 74px;
    }
  }
}
@import '../../../styles/mixins/main.scss';

.pricing-page-logo {
    padding: 24px 0;

    img {
        @include pointer;
    }
}

.pricing-card-all-alignment1 {
    padding: 0 0 0 0;

    .new-grid {
        display: flex;
        justify-content: center;
        gap: 24px;
        flex-wrap: wrap;


        .fix-width {
            min-width: 300px;
            max-width: 300px;
            width: 300px;
        }

        .radius-remove {}

        .grid-items {
            background: #FFFFFF;
            box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.15);
            border-radius: 8px;
            border: 1px solid transparent;
            @include pointer;
            padding: 16px 24px;
            transition: .3s ease-in-out;
            //padding-right: 0px;

            &:hover {
                background: #FFFFFF;
                border: 1px solid #1EC3CB;
                box-shadow: 0px 0px 40px rgba(30, 195, 203, 0.15);
            }

            .child-box-height {
                min-height: 653px;
                overflow: auto;

                @include breakpoint("max-sm") {
                    height: auto;
                    min-height: auto;
                }
            }

            .fill-button {
                padding: 00px 24px 0 0;

                button {
                    transition: .3s ease-in-out;

                    &:hover {
                        background-color: var(--primary-color);
                    }
                }
            }

            .icon-text-alignment {
                display: flex;
                padding: 0 0 16px 0;

                div {
                    padding-left: 8px;

                    img {
                        width: 12px;
                    }

                    span {
                        max-width: 160px;
                        @include text-sm;
                    }
                }
            }

            .plan-amount-height-box {
                min-height: 100px;

                h1 {
                    @include heading-3;
                    color: var(--black-5);
                    @include text-center;
                    margin: 0 0 0 0;

                    sub {
                        position: relative;
                        top: -5px;
                        font-size: 16px;
                    }
                }
            }

            .image-center-alignment {
                padding: 0 0 24px 0;
                height: 110px;
                @include flex-center;
                justify-content: center;

                img {
                    max-width: 113px;
                }
            }

            .card-title {
                padding: 0 0 24px 0;

                p {
                    color: var(--black-3);
                    @include heading-4;
                    text-align: center;
                }
            }
        }
    }
}

.most-popular-card {
    button {
        @include text-bold;
        color: #fff;
        text-align: center;
        background: #1EC3CB;
        border-radius: 8px 8px 0 0;
        border: none;
        height: 35px;
        width: 100%;
    }
}

.white-space-empty {
    height: 35px;
    width: 100%;
}
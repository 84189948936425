.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Adjust the opacity as needed */
    z-index: 999; /* Ensure it is behind the modal but above other content */
}

.dialog {
    position: relative; /* Ensure the modal is positioned relative to the pseudo-element */
    background-color: white;
    border: 1px solid #ccc;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 20px;
    width: 80%;
    max-width: 500px;
    margin: auto;
    animation: fadeIn 0.3s ease-in-out;
    z-index: 1000; /* Ensure it is above the overlay */
}

@keyframes fadeIn {
    from {
        opacity: 0;
        transform: translateY(-10px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

.modal-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 1rem;
}

.close-button {
    background: none;
    border: none;
    font-size: 1.5rem;
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
    color: #aaa;
    transition: color 0.2s;

    &:hover {
        color: #000;
    }
}

@import '../../../styles/mixins/main.scss';

.countries-section-alignment {
    .page-alignment {
        padding: 0 0 40px 0;
        @include flex-between;
        div {
            h1 {
                @include heading-3;
                color: var(--black-3);
                margin: 0;
            }
        }
        div + div {
            img {
                @include pointer;
            }
        }
    }
    .search-result-box{
        max-width: 353px;
        .box {
            padding: 11px 12px;
            background: #F8F8F8;
            box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.05);
            border-radius: 8px;
            margin: 0 0 12px 0;
            @include flex-between;
            @include pointer;
            div {
                span {
                    @include text;
                    color: var(--black-3);
                    display: block;
                }
                img {
                    width: 10px;
                }
            }

        }
    }
    .countries-search {
        margin: 0 0 40px 0;
        max-width: 353px;
        .input {
            position: relative;
            input {
                padding-left: 40px;
            }
            .search-icon-alignment {
                @include flex-center;
                position: absolute;
                top: 50%;
                left: 12px;
                transform: translateY(-50%);
            }
        }
    }
    .countries-list-alignment {
        width: 50%;
        @include breakpoint("max-sm"){
            width: 100%;
        }
        padding: 0 0 24px 0;
        margin-bottom: 16px;
        border-bottom: 1px solid black;
        span{
            @include flex-between;
        }
        p {
            @include text-bold;
            color: var(--primary-color);
            margin: 0;
        }
        img{
            width: 16px;
            border-radius: 2px;
            margin-right: 12px;
        }
    }

    .countries-total-price-alignment{
        margin-bottom: 32px;
    }

    .save-button {
        display: block;
        //padding: 30px 0 0 0;
        margin-bottom: 32px;

        button {
            padding: 14px 40px;
            background-color: var(--invert-color);
            border: none;
            font-size: 18px;
            line-height: 21px;
            font-family: var(--default-font-family);
            font-weight: 700;
            color: var(--text-white);
            border-radius: 8px;
            cursor: pointer;
        }
    }

    .countries-grid {
        @include grid-layout;
        grid-template-columns: repeat(1 , 1fr);
        gap: 0 0;
        @include breakpoint("max-md") {
            grid-template-columns: repeat(1 , 1fr);
            gap: 60px;
        }
        .countries-grid-items {
            padding: map-get($pixel, sm ) * 3 + 1px;
            background: rgba(255, 255, 255, 0.6);
            mix-blend-mode: normal;
            backdrop-filter: blur(14px);
            //height: calc(100vh - 128px - 110px);
            //margin-bottom: 16px;
            //overflow: auto;
            //overflow-x: hidden;
            //@include breakpoint("max-sm") {
            //    height: calc(100vh - 128px - 71px);
            //}
            h1 {
                margin-bottom: 50px;
            }
            .countries-sub-grid {
                @include grid-layout;
                grid-template-columns: repeat(12 , 1fr);
                gap: 16px 24px;
                @include breakpoint("max-sm") {
                    grid-template-columns: repeat(1 , 1fr);
                }
                .selected {
                    border: 1px solid var(--primary-color) !important;
                }
                .countries-sub-grid-items {
                    grid-column: span 4;
                    background-color: var(--bg-white);
                    @include normal-radius;
                    padding: map-get($pixel, sm ) * 3 + 1px;
                    border: 1px solid transparent;
                    @include pointer;
                    @include normal-shadow;
                    &:hover {
                        border: 1px solid var(--primary-color);
                    }
                    @include breakpoint("max-sm") {
                        grid-column: auto;
                    }
                    p {
                        @include text-sm;
                        margin: 0;
                        color: var(--invert-color);
                    }
                    .box-title-alignment {
                        @include flex-center;
                        padding: 0 0 map-get($pixel, sm ) * 3 + 1px 0;
                        div {
                            span {
                                @include text;
                                display: block;
                                padding-left: 8px;
                                color: var(--black-color);
                                display: block;
                            }
                        }

                        .flag-section{

                            span{
                                display: flex;
                            }

                            img{
                                width: 20px;
                                height: 15px;
                                margin-right: .3125rem;
                                border-radius: 2px;
                            }
                        }

                        .title-section{
                            display: flex;
                            justify-content: space-between;
                            width: 90%;
                        }
                    }
                }
            }
        }
        .grid-items {
            .map-show {
                @include breakpoint("max-md") {
                    background-color: #fff;
                }

            }
            h1 {
                @include heading-2;
                color: var(--black-color);
                text-align: left;
                margin: 0 0 42px 0;
            }

            .terms-conditions {
                @include flex-center;
                padding: 28px 0 40px 0;
                label {
                    @include flex-center;
                }
                justify-content: map-get($layout, center );
                div {
                    @include flex-center;
                    input[type="checkbox"] {
                        width: 18px;
                        height: 18px;
                        margin: 0;
                        padding: 0;
                    }
                    span {
                        @include text;
                        color: var(--invert-color);
                        padding-left: 8px;
                    }
                }
            }
        }
    }

}
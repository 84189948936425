.header-buttons {
    position: static;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 30px;
    margin-bottom: -100px;
    margin-left: 70px;
}

@media (max-width: 768px) {
    .header-buttons {
        margin-top: 20px;
        margin-bottom: -60px;
        margin-left: 20px;
        justify-content: start;
    }
}
@import '../../../styles/mixins/main.scss';

.region-all-content-alignment {
    .radio-region-alignment {
        position: relative;
    }

    .sec-content-alignment-left-1 {
        padding-left: 40px !important;
    }
    .sec-content-alignment-left-2 {
        padding-left: 80px !important;
    }
    .sec-content-alignment-left-3 {
        padding-left: 120px !important;
    }
    .sec-content-alignment-left-4 {
        padding-left: 160px !important;
    }
    .sec-content-alignment-left-5 {
        padding-left: 200px !important;
    }
    .sec-content-alignment-left-6 {
        padding-left: 240px !important;
    }
    .search-input {
        position: relative;
        margin: 0 0 24px 0;
        //border-bottom: 1px solid var(--light-border);
        .search-icon-alignment{
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            left: 16px;
            @include flex-center;
            img {
                width: 24px;
                height: 24px;
            }
        }
        ::placeholder {
            @include text-sm;
            line-height: normal;
            vertical-align: baseline;
            color: var(--black-5);
        }
        input {
            width: calc(100% - 50%);
            height: 50px;
            vertical-align: baseline;
            border: none;
            background-color: var(--bg-light);
            border-radius: 9999px;
            padding-left: 44px;
            padding-right: 20px;
            @include text-sm;
            line-height: normal;
            color: var(--black-5);
            @include breakpoint("max-sm") {
                width: 100%;
            }
        }
    }
}

.all-content-accreditation-list-alignment {
    margin-bottom: 24px;
    .all-content-hidden {
        max-height: 0px;
        overflow: hidden;
        transition: .3s ease-in-out;
    }
    .all-content-show {
        max-height: calc(100vh - 0px);
        overflow: hidden;
        transition: .3s ease-in-out;
    }
    .sec-content-alignment {
        padding-left: 35px;
        margin: 0 0 5px 0;
        @include flex-center;
        .child-product {
            label {
                @include flex-center;
                span {
                    padding-left: 8px;
                    @include text-sm;
                    color: var(--black-5)
                }
            }
            @include flex-center;
            
        }
        
        div {
            margin-right: 8px;
            i {
                font-size: 12px;
                color: var(--black-5);
                @include pointer;
                position: relative;
                top: -2px;

            }
        }
    }
    .first-layout-alignment {
        @include flex-center;
        margin: 0 0 5px 0;
        div {
            margin-right: 8px;
            i {
                font-size: 12px;
                color: var(--black-5);
                @include pointer;
                position: relative;
                top: -2px;

            }
        }
        .child-product {
       
            label {
                @include flex-center;
                span {
                    padding-left: 8px;
                    @include text-sm;
                    color: var(--black-5);
                    font-weight: 600;
                    max-width: 95%;
                }
            }
            @include flex-center;
        }
    }
    img {
        //for the + and minus icons
        @include pointer;
        max-width: 8px;
    }
    .center-align{
        display: flex;
        align-items: center;
    }
}

.no-results-text{
    @include text-sm;
    padding-left: 12px;
    color: var(--black-5);
}
.container {
    display: flex;
    flex-direction: column;
    align-items: start;   
    background-color: var(--bg-light);
    border-radius: 8px;
    margin: 8px;
    padding: 12px;
    width: 242px;
    height: 149px;
    transition: all 0.3s ease-out;
    overflow: hidden;

}

.container h3 {
    font-size: 1rem;
    font-weight: bold;
}

.container h3 img {
    width: 1.2rem;
    height: auto;
    margin-right: 1rem;
    margin-top: auto;
    margin-bottom: auto;
}

.container:hover {
    transform: scale(1.02);
    border: 1px solid var(--primary-color);
    cursor: pointer;
}


.container.selected {
    border-width: 2px;
    border-style: solid;
    border-color: var(--primary-color);
    height: 242px;
    width: 500px;
    transition: all 0.3s ease-out;
}
.container.selected:hover {
    cursor: unset;
    transform: none;
}

@media (max-width: 600px) {
    .container {
        width: 100%;
        height: 90px;
        margin: 4px 0;
    }
}

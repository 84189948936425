
.skeleton {
    border-radius: 10px;
    margin: 1.5em;
    background-color: var(--bg-light);
}

.skeleton-pulse-background{
    background-color: var(--bg-light);
    animation: skeleton-pulse-background 4s infinite linear;
}

@keyframes skeleton-pulse-background {
    0% {background-color: var(--bg-light);}
    50% {background-color: var(--bg-light-grey);}
    100% {background-color: var(--bg-light);}
}

.skeleton-wave-background{
    background: linear-gradient(-45deg, var(--bg-white), var(--bg-light));
    background-size: 500% 500%;
    animation: skeleton-wave-background 4s ease infinite;
}

@keyframes skeleton-wave-background {
    0% {
        background-position: 0% 50%;
    }
    50% {
        background-position: 100% 50%;
    }
    100% {
        background-position: 0% 50%;
    }
}
@import '../../styles/mixins/main.scss';


.categories{

  @include text;
  position: relative;
  z-index: 1;
  margin: 36px 0 24px 0;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: start;
  align-items: start;
  grid-column-gap: 1rem;
  column-gap: 1rem;
  background-color: var(--bg-white);
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-bottom-color: var(--bg-light-grey);

  span{
    cursor: pointer;
    //opacity: 0.6;
    color: var(--black-3);
    border-bottom-color: var(--black-3);
    padding: 4px;
    //font-weight: bold;
  }

  .selected{
    opacity: 1;
    color: var(--primary-color);
  }
}

.filter-buttons{
  margin-bottom: 24px;
}

.result-hints {

  margin: 12px 0 24px 0;
  display: flex;
  justify-content: space-between;

  p{
    @include text-sm;
    color: var(--black-5);
  }

  div {
    display: flex;
    flex-direction: row;
    gap: 2px;
  }
}

.sidebar {
  display: flex;
  flex-direction: column;
  gap: 24px;
  background-color: var(--bg-white);
  box-shadow: var(--normal-box-shadow);
  padding: 16px;
  border-radius: 8px;

  .filter-title{
    font-size: 16px;
    line-height: 19px;
    font-family: var(--default-font-family);
    font-weight: 700;
    color: var(--primary-color);
    display: block;
  }

  .top-search{
    margin: 0 0 16px 0;
    padding: 0 0 24px 0;
    border-bottom: 1px solid var(--light-border);
  }
}
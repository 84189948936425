$color-red: rgba(219,7,61,1);
$color-red-background: rgba(219,7,61,0.1);

$color-yellow: rgb(177, 133, 4);
$color-yellow-background: rgba(219,165,7,0.1);

$color-lightblue: rgb(103, 142, 150);
$color-lightblue-background: rgba(142, 199, 210,0.1);

$color-blue: rgb(29, 168, 211);
$color-blue-background: rgba(29,168,211,0.1);

$color-darkblue: rgba(7, 72, 91,1);
$color-darkblue-background: rgba(7, 72, 91,0.1);

$color-pink: rgba(159,94,131,1);
$color-pink-background: rgba(159,94,131,0.1);

$color-purple: rgba(55,42,130,1);
$color-purple-background: rgba(55,42,130,0.1);

$color-lime: rgb(66, 161, 114);
$color-lime-background: rgba(92,214,153,0.1);

$color-sand: rgb(155, 142, 2);
$color-sand-background: rgba(187,173,1,0.1);

$color-moss: rgb(105, 121, 70);
$color-moss-background: rgba(129,148,85,0.1);

$color-orange: rgb(218, 142, 2);
$color-orange-background: rgba(255, 165, 0, 0.1);

$color-green: rgb(34, 139, 34);
$color-green-background: rgba(34, 139, 34, 0.1);

$color-teal: rgb(0, 128, 128);
$color-teal-background: rgba(0, 128, 128, 0.1);

$color-coral: rgb(211, 106, 68);
$color-coral-background: rgba(255, 127, 80, 0.1);

$color-brown: rgb(165, 42, 42);
$color-brown-background: rgba(165, 42, 42, 0.1);

$color-maroon: rgb(128, 0, 0);
$color-maroon-background: rgba(128, 0, 0, 0.1);

$color-olive: rgb(107, 107, 1);
$color-olive-background: rgba(128, 128, 0, 0.1);

$color-turquoise: rgb(56, 194, 180);
$color-turquoise-background: rgba(64, 224, 208, 0.1);

$color-indigo: rgb(75, 0, 130);
$color-indigo-background: rgba(75, 0, 130, 0.1);

$color-gold: rgb(204, 174, 5);
$color-gold-background: rgba(255, 215, 0, 0.1);

$color-aqua: rgb(2, 180, 180);
$color-aqua-background: rgba(0, 255, 255, 0.1);

$color-magenta: rgb(200, 1, 200);
$color-magenta-background: rgba(255, 0, 255, 0.1);

$color-navy: rgba(0, 0, 128, 1);
$color-navy-background: rgba(0, 0, 128, 0.1);

$color-silver: rgb(124, 124, 124);
$color-silver-background: rgba(192, 192, 192, 0.1);

$color-lavender: rgb(100, 100, 138);
$color-lavender-background: rgba(230, 230, 250, 0.1);

$color-plum: rgb(179, 129, 179);
$color-plum-background: rgba(221, 160, 221, 0.1);

$color-peach: rgb(173, 139, 110);
$color-peach-background: rgba(255, 218, 185, 0.1);

$color-mint: rgb(113, 182, 126);
$color-mint-background: rgba(189, 252, 201, 0.1);

$color-charcoal: rgba(54, 69, 79, 1);
$color-charcoal-background: rgba(54, 69, 79, 0.1);

$color-amber: rgb(200, 151, 4);
$color-amber-background: rgba(255, 191, 0, 0.1);

$color-periwinkle: rgb(122, 122, 188);
$color-periwinkle-background: rgba(204, 204, 255, 0.1);

$color-raspberry: rgba(227, 11, 92, 1);
$color-raspberry-background: rgba(227, 11, 92, 0.1);

$color-jade: rgb(2, 151, 96);
$color-jade-background: rgba(0, 168, 107, 0.1);

$color-crimson: rgba(220, 20, 60, 1);
$color-crimson-background: rgba(220, 20, 60, 0.1);

$color-beige: rgb(139, 139, 69);
$color-beige-background: rgba(245, 245, 220, 0.1);

$color-lilac: rgb(165, 103, 165);
$color-lilac-background: rgba(200, 162, 200, 0.1);

$color-aubergine: rgba(59, 9, 16, 1);
$color-aubergine-background: rgba(59, 9, 16, 0.1);

$color-emerald: rgb(55, 170, 93);
$color-emerald-background: rgba(80, 200, 120, 0.1);

$color-cobalt: rgba(0, 71, 171, 1);
$color-cobalt-background: rgba(0, 71, 171, 0.1);

$color-honey: rgb(218, 138, 27);
$color-honey-background: rgba(255, 185, 85, 0.1);



$tag-color: rgba(39, 108, 235, 0.99);
$tag-background-color: rgba(39, 108, 235, 0.10);


.tag {
  padding: 4px 12px;
  background-color: $tag-background-color;
  color: $tag-color;
  font-size: 0.80rem;
  font-weight: bold;
  border-radius: 5px;
  border-color: $tag-background-color;
  border-width: 1px;
  border-style: solid;
  cursor: default;
  transition: transform 0.1s;
  &:hover {
    transform: scale(1.02);
  }

  &.red {
    background-color: $color-red-background;
    color: $color-red;
    border-color: $color-red-background;
  }

  &.yellow {
    background-color: $color-yellow-background;
    color: $color-yellow;
    border-color: $color-yellow-background;
  }

  &.lightblue {
    background-color: $color-lightblue-background;
    color: $color-lightblue;
    border-color: $color-lightblue-background;
  }

  &.blue {
    background-color: $color-blue-background;
    color: $color-blue;
    border-color: $color-blue-background;
  }

  &.darkblue {
    background-color: $color-darkblue-background;
    color: $color-darkblue;
    border-color: $color-darkblue-background;
  }

  &.pink {
    background-color: $color-pink-background;
    color: $color-pink;
    border-color: $color-pink-background;
  }

  &.purple {
    background-color: $color-purple-background;
    color: $color-purple;
    border-color: $color-purple-background;
  }

  &.lime {
    background-color: $color-lime-background;
    color: $color-lime;
    border-color: $color-lime-background;
  }

  &.sand {
    background-color: $color-sand-background;
    color: $color-sand;
    border-color: $color-sand-background;
  }

  &.moss {
    background-color: $color-moss-background;
    color: $color-moss;
    border-color: $color-moss-background;
  }

  &.orange {
    background-color: $color-orange-background;
    color: $color-orange;
    border-color: $color-orange-background;
  }

  &.green {
    background-color: $color-green-background;
    color: $color-green;
    border-color: $color-green-background;
  }

  &.teal {
    background-color: $color-teal-background;
    color: $color-teal;
    border-color: $color-teal-background;
  }

  &.coral {
    background-color: $color-coral-background;
    color: $color-coral;
    border-color: $color-coral-background;
  }

  &.brown {
    background-color: $color-brown-background;
    color: $color-brown;
    border-color: $color-brown-background;
  }

  &.maroon {
    background-color: $color-maroon-background;
    color: $color-maroon;
    border-color: $color-maroon-background;
  }

  &.olive {
    background-color: $color-olive-background;
    color: $color-olive;
    border-color: $color-olive-background;
  }

  &.turquoise {
    background-color: $color-turquoise-background;
    color: $color-turquoise;
    border-color: $color-turquoise-background;
  }

  &.indigo {
    background-color: $color-indigo-background;
    color: $color-indigo;
    border-color: $color-indigo-background;
  }

  &.gold {
    background-color: $color-gold-background;
    color: $color-gold;
    border-color: $color-gold-background;
  }

  &.aqua {
    background-color: $color-aqua-background;
    color: $color-aqua;
    border-color: $color-aqua-background;
  }

  &.magenta {
    background-color: $color-magenta-background;
    color: $color-magenta;
    border-color: $color-magenta-background;
  }

  &.navy {
    background-color: $color-navy-background;
    color: $color-navy;
    border-color: $color-navy-background;
  }

  &.silver {
    background-color: $color-silver-background;
    color: $color-silver;
    border-color: $color-silver-background;
  }

  &.lavender {
    background-color: $color-lavender-background;
    color: $color-lavender;
    border-color: $color-lavender-background;
  }

  &.plum {
    background-color: $color-plum-background;
    color: $color-plum;
    border-color: $color-plum-background;
  }

  &.peach {
    background-color: $color-peach-background;
    color: $color-peach;
    border-color: $color-peach-background;
  }

  &.mint {
    background-color: $color-mint-background;
    color: $color-mint;
    border-color: $color-mint-background;
  }

  &.charcoal {
    background-color: $color-charcoal-background;
    color: $color-charcoal;
    border-color: $color-charcoal-background;
  }

  &.amber {
    background-color: $color-amber-background;
    color: $color-amber;
    border-color: $color-amber-background;
  }

  &.periwinkle {
    background-color: $color-periwinkle-background;
    color: $color-periwinkle;
    border-color: $color-periwinkle-background;
  }

  &.raspberry {
    background-color: $color-raspberry-background;
    color: $color-raspberry;
    border-color: $color-raspberry-background;
  }

  &.jade {
    background-color: $color-jade-background;
    color: $color-jade;
    border-color: $color-jade-background;
  }

  &.crimson {
    background-color: $color-crimson-background;
    color: $color-crimson;
    border-color: $color-crimson-background;
  }

  &.beige {
    background-color: $color-beige-background;
    color: $color-beige;
    border-color: $color-beige-background;
  }

  &.lilac {
    background-color: $color-lilac-background;
    color: $color-lilac;
    border-color: $color-lilac-background;
  }

  &.aubergine {
    background-color: $color-aubergine-background;
    color: $color-aubergine;
    border-color: $color-aubergine-background;
  }

  &.emerald {
    background-color: $color-emerald-background;
    color: $color-emerald;
    border-color: $color-emerald-background;
  }

  &.cobalt {
    background-color: $color-cobalt-background;
    color: $color-cobalt;
    border-color: $color-cobalt-background;
  }

  &.honey {
    background-color: $color-honey-background;
    color: $color-honey;
    border-color: $color-honey-background;
  }
}
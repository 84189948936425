@import '../../styles/mixins/main.scss';

.spacer-while-loading {
    height: 100vh;
}

.export-button {
    display: flex;
    justify-content: end;
    align-items: center;
    padding: 0.5rem 0.5rem;
    img {
        width: 1.5rem;
        height: 1.5rem;
    }
}


.cpv-icon{
    position: relative;
    width: auto;
    height: 0.75rem;
    transform: scale(1.5) translateY(0.1rem) translateX(-0.2rem);
    margin-right: 0.5rem;
    opacity: 0.6;
}

.company-header {
    display: flex;
    flex-direction: row;
    justify-content:space-between;
    align-items: start;
    padding-top: 1.5rem;
    width: 100%;
    height: 190px;

    .company-header-left {
        width: 50%;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items:start;
        justify-content: space-between;
    }


    .company-header-right {
        width: 50%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        z-index: 2;
        > * {
            width: 25%; 
        }
    }
}

.tab-panel-container {
    padding: 2rem 0rem;
    width: 100%;
}
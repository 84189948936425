@import '../../styles/mixins/main.scss';

.setting-section-alignment {
    margin: 40px 0;
    height: calc(100vh - 171px);
    @include breakpoint("max-sm") {
        height: auto;
    }
    .setting-box {
        background-color: var(--bg-white);
        box-shadow: var(--normal-box-shadow);
        border-radius: 8px;
        padding: 24px;
        //height: calc(100vh - 171px); //doesnt' work for mobile
        @include breakpoint("max-sm") {
            padding: 10px;
        }
        .setting-child-box {
            height: calc(100vh - 244px - 60px);
            padding: 0 0 24px 0;
            overflow: auto;
            overflow-x: hidden;
            @include breakpoint("max-sm") {
                height: auto;
            }
            .setting-all-content-show {
                padding: 40px 0 0 0;
            }
            .tab {
                .active-tab {
                    color: #2A374E;
                    background-color: transparent !important;
                    border-color: #DDDDDD #DDDDDD #fff;
                }
                ul {
                    margin: 0;
                    padding: 0;
                    list-style: none;
                    @include flex-center;
                    border-bottom: 1px solid #DDDDDD;
                    @include breakpoint("max-sm"){display: grid;}
                    li {
                        margin-right: 6px;
                        cursor: pointer;
                        span {
                            margin-bottom: -1px;
                            background: #F8F8F8 !important;
                            background: 0 0;
                            display: block;
                            color: #2A374E;
                            @include text-sm;
                             padding: 14px;
                             font-weight: 500;
                            border: 1px solid #DDDDDD;
                            border-top-left-radius: 0.25rem;
                            border-top-right-radius: 0.25rem;
                            @include breakpoint("max-sm") {
                                font-size: 10px;
                                line-height: 16px;
                                padding: 10px;
                            }
                        }
                    }
                }
            }
        }
    }
}

.filter-back-text {
    @include flex-center;
    @include pointer;
    justify-content: flex-end;
    a {
        @include flex-center;
    }
    span {
        @include text-sm;
        display: block;
        color: var(--primary-color);
    }
    div {
        padding-right: 6px;
    }
}
@import '../../../styles/mixins/main.scss';


.email-page-alignment {
    .email-add-box {
        max-width: 616px;
        .add-email {
            @include flex-center;
            a {
                @include text-bold;
                color: var(--primary-color);
                @include pointer;
            }
            .valid{
                border-color: var(--primary-color);
            }
            .invalid{
                border-color: var(--red);
            }
            img{
                margin-left: 20px;
                width: 16px;
                @include pointer;
            }
        }
        .fill-button {
            padding: 40px 0 0 0;
            display: block;
        }
        label {
            @include text-bold;
            color: var(--black-3);
            padding: 0 0 1.5rem 0;
            display: block;
        }
        .email-card:hover {
            background-color: var(--bg-light-grey);
        }
        .email-card {
            padding: 10px 16px;
            background-color: var(--bg-white);
            @include flex-between;
            box-shadow: 0px 0px 10px rgba(9, 20, 41, 0.05);
            border-radius: 8px;
            margin: 0 0 12px 0;
            border: 1px solid var(--border-color-1);
            //width: 30px;
            height: 50px;
            div:last-child {
                @include flex-center;
            }
            .edit-buttons{
                display: flex;
                justify-content: right;
                align-content: center;
                gap: 8px;
                img{
                    width: 12px;
                    @include pointer;
                }
            }
            div {
                span {
                    @include text-sm;
                    color: var(--black-5);
                    display: block;
                }
            }
        }
    }
    .checkbox-text-alignment {
        @include flex-center;
        padding: 0 0 map-get($pixel, md ) * 3 0;
        div {
            @include flex-center;
            input[type="checkbox"] {
                width: 18px;
                height: 18px;
                margin: 0 5px 0 0;
                padding: 0;
            }
            label {
                @include text-bold;
                @include flex-center;
                font-weight: 500;
                color: var(--invert-color);
                span {
                    padding-left: 9px;
                }
                a {
                    color: var(--primary-color);
                    text-decoration: underline;
                    padding-right: 30px;
                    @include pointer;
                }
            }

        }
    }
    .page-alignment {
        padding: 0 0 1.5rem 0;
        @include flex-between;
        div {
            h1 {
                @include heading-3;
                color: var(--black-3);
                margin: 0;
            }
        }
        div + div {
            img {
                @include pointer;
            }
        }
    }
    .child-text-alignment {
        padding: 0 0 26px 0;
        @include flex-between;
        div {
            p {
                @include text-sm;
                color: var(--black-5);
                margin: 0;
            }
        }
    }
}

.make-it-primary {
    text-decoration: underline;
    color: var(--black-3) !important;
    @include pointer;
    @include text-sm;
    margin-right: 8px;
}
@import 'src/styles/mixins/main';

.child-sidebar-design {
    width: 192px;
    height: fit-content;
    border: 1px solid var(--primary-color);
    border-radius: 8px;
    padding: 16px;
    margin: auto;
    z-index: 1;
    background-color: var(--bg-white);
    @include slide-in-right;
    @media (max-width: 1024px) {
        display: none;
    }
    ul {
        margin: 0;
        padding: 0;
        list-style: none;
        li:first-child {
            padding-top: 0px;
        }
        li:last-child {
            border: none;
            padding-bottom: 0px;
        }
        li {
            text-decoration: none;
            @include pointer;
            @include flex-center;
            padding: 8px 0;
            border-bottom: 0.5px solid #A3E4DB;
            div + div {
                padding-left: 7px;
                span {
                    @include text-sm;
                    color: var(--black-3);
                }
            }
        }
    }
    .center-align{
        display: flex;
        align-items: center;
        img {
            width: 16px;
        }

        .label-button-alignment{
            //overwriting some of the css from the label component
            padding: 0;

            button {
                margin: 0 2px 0 0;
            }
        }
    }

    .converted-icon{
        img{
            width: 16px;
            filter: invert(63%) sepia(49%) saturate(659%) hue-rotate(134deg) brightness(91%) contrast(93%);
        }
    }
}

.emails-input:focus-within {
    //this applies focus on the DebounceInput input, hence below the adjusted radius
    outline: none;
    @include ease-in-out;
    border: 1px solid var(--primary-color);
}

.emails-input{
    @include flex-left;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 6px;
    padding: 6px;

    width: 100%;
    background-color: var(--bg-light);
    //padding: 6px;
    margin: 12px 0px 12px 0px;
    font-size: 14px;
    font-family: var(--default-font-family);
    font-weight: 400;
    color: var(--invert-color);
    transition: 0.3s ease-in-out;
    border-radius: 8px;
    font-weight: 400;
    box-shadow: var(--normal-box-shadow);
    //border: 1px solid transparent;
    line-height: normal;



    .emails-input-item{
        //border: 1px solid var(--bg-grey);
        @include flex-center;
        //padding: 6px 0px 6px 6px;

        > span{
            padding: 6px;
            border-radius: 5px;
            background-color: var(--bg-light-grey);
            line-height: 19px;
        }

        img{
            width: 8px;
            margin-left: 3px;
        }
    }

    .new-email-input{
        flex-grow: 1; //makes sure the input fills the remaining horizontal space
        background-color: transparent;
        border: none;
        position: relative;
        //box-shadow: none;

        div > input{
            padding: 6px; //overrides datalist input component css
            height: auto; //overrides datalist input component css
        }

        div > div > img{
            width: 0; //overrides datalist input component css
        }

        div > span.datalist-item{
            min-width: 300px;
        }

    }

}

.share-publication-modal-wrapper {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    @include flex-center;
    justify-content: center;
    background: rgba(48, 52, 60, 0.25);
    z-index: 999;

    .publication-modal {
        width: 35%;
        @include breakpoint("max-md") {
            width: 95%;
        }
        background: #FFFFFF;
        border: 1px solid #F8F8F8;
        box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.1);
        padding: 24px;
        border-radius: 4px;

        img {
            cursor: pointer;
        }

        .via-email-content {
            padding: 0 0 16px 0;

            p {
                @include text-sm;
                font-weight: 600;
                color: var(--black-3);
            }
        }

        .already-shared-with-content{
            padding: 0 0 16px 0;

            p {
                @include text-sm;
                //font-weight: 600;
                color: var(--black-3);
            }
        }

        .via-link-text-style {
            span {
                @include text-sm;
                font-weight: 700;
                color: var(--black-3);
                display: block;
                padding: 0 0 10px 0;
            }

            .input {
                margin: 0;
            }
        }

        .part-all-content-alignment {
            @include flex-between;
            padding: 0 0 0 0;
            @include breakpoint("max-lg") {
                flex-direction: column-reverse;
            }

            .part-first-section-alignment {
                @include flex-left;
                //width: 20%;
                .fill-button {
                    padding-right: 16px;

                    button {
                        padding: 9px 20px;
                        font-size: 16px;
                        font-weight: 700;
                    }
                }

                .child-product {
                    label {
                        @include flex-center;

                        span {
                            padding: 8px;
                            @include text-sm;
                            color: #51565F;
                        }
                    }
                }
            }
            .part-second-section-alignment{
                @include flex-right;
                min-width: 80%;
                margin: 5px;
                @include breakpoint("max-lg") {
                    @include flex-center;
                    flex-direction: column;
                    margin-top: 24px;
                }

                label{
                    @include flex-center;
                }

                span {
                    padding-right: 15px;
                }

                .input{
                    margin: 24px 8px 24px 8px;
                    //padding: 5px;
                    min-width: 60%;

                }
            }
        }

        .input {
            margin: 0 0 24px 0;

            label {
                @include text-sm;
                color: var(--black-5);
                display: block;
                padding: 0 0 8px 0;
                margin-bottom: 6px;
            }

            textarea {
                max-width: 100%;
            }
        }

        .header-alignment {
            @include flex-center;
            justify-content: space-between;
            padding: 0 0 24px 0;

            p {
                @include text-bold;
                color: var(--black-3);
                margin: 0;
            }

            img{
                width: 16px;
            }
        }
    }
}
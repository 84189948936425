@import '../../styles/mixins/main.scss';

footer {
    background-color: var(--black-3);
    padding: 60px 0 24px 0;
    .copy-right-text {
        padding: 24px 0 0 0;
        span {
            display: block;
            text-align: center;
            @include text-sm;
            color: var(--text-white);
        }
    }
    .footer-grid {
        display: grid;
        grid-template-columns: 311px minmax(0 , 1fr);
        gap: 0 100px;
        padding:  0 0 24px 0;
        border-bottom: 1px solid var(--text-white);
        @include breakpoint("max-md") {
            grid-template-columns: repeat(1 , 1fr);
            gap: 10px;
        }
        .footer-grid-items {
            .footer-sub-grid {
                display: grid;
                grid-template-columns: 109px 156px minmax(0 , 1fr);
                gap: 0 100px;
                @include breakpoint("max-sm") {
                    grid-template-columns: repeat(1 , 1fr);
                    gap: 10px;
                }
                .footer-sub-grid-items {
                    h3 {
                        @include text-bold;
                        margin: 0 0 12px 0;
                        color: var(--text-white);
                    }
                    .icon-text-alignment {
                        @include flex-center;
                        padding: 0 0 15px 0;
                        div + div{
                            padding-left: 16px;
                            span {
                                @include text;
                                color: var(--text-white);
                            }
                        }
                        img{
                            width: 24px;
                        }
                    }
                    ul {
                        margin: 0;
                        padding: 0;
                        li:last-child {
                            padding: 0;
                        }
                        li {
                            @include text;
                            @include pointer;
                            color: var(--text-white);
                            padding: 0 0 8px 0;
                        }

                    }
                }
            }
            .footer-logo {
                padding: 0 0 27px 0;
                img {
                    @include pointer;
                    max-width: 210px;
                }
            }
            .follow-us {
                h2 {
                    @include text-bold;
                    color: var(--text-white);
                    margin: 0 0 20px 0;
                }
                .social-alignment {
                    @include flex-center;
                    div:last-child {
                        margin: 0;
                    }
                    div {
                        background: rgba(255, 255, 255, 0.2);
                        border-radius: 4px;
                        width: 28px;
                        margin-right: 16px;
                        height: 28px;
                        @include flex-center;
                        justify-content: center;
                        @include pointer;
                    }
                }
            }
            p {
                @include text;
                color: var(--text-white);
                margin: 0 0 20px 0;
            }
        }
    }

    .svg-black-to-white{
        filter: invert(1);
    }
}
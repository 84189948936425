.what-is-tenderwolf-button {
    z-index: 1;
    color: #343a40;
    border: none;
    padding: 8px 16px;
    margin-left: 1rem;
    font-size: 16px;
    cursor: pointer;
    border-radius: 5px;
    transition: all 0.25s ease;
    font-weight: bold;
    &:hover{
        transform: scale(1.05);
    };
};

@media (max-width: 768px) {
    .what-is-tenderwolf-button {
        margin-left: 4px;
        font-size: 0.8rem;
    }
}

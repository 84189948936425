@import '../../../styles/mixins/main.scss';

.feedback-section-alignment {
    .page-alignment {
        padding: 0 0 40px 0;
        @include flex-between;
        div {
            h1 {
                @include heading-3;
                color: var(--black-3);
                margin: 0;
            }
        }
        div + div {
            img {
                @include pointer;
            }
        }
    }
    .feedback-type-message {
        max-width: 683px;
    }
    .fill-button {
        display: block;
        padding: 60px 0 0 0;
    }
    .input-box {
        max-width: 462px;
        .input{
            margin: 0 0 24px 0;
        }
    }
    .feedback-dec-alignment {
        padding: 0 0 40px 0;
        h2 {
            @include text-md-bold;
            margin: 0 0 1rem 0;
            font-weight: 400;
            color: var(--black-3);
        }
        p {
            @include text-sm;
            color: var(--black-3);
            margin: 0 0 1rem 0;
            max-width: 930px;
        }
        span {
            @include text-sm;
            color: var(--black-3);
            display: block;
        }
        h5 {
            @include text-bold;
            color: var(--black-3);
            margin: 24px 0 0 0;
        }
    }
}
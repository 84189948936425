.tender {
    width: 150px;
    height: 65px;
    background-color: var(--bg-white);
    border: 2px solid var(--border-color-1);
    border-radius: 10px;
    z-index: 0;
    transition: all 0.3;
    padding: 4px;
    display: flex; 
    flex-direction: column;
    justify-content: space-between;
    align-items: start;
    gap: 8px;
    &:hover,
    &:focus {
        box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1), 0px -4px 8px rgba(0, 0, 0, 0.1), 4px 0px 8px rgba(0, 0, 0, 0.1), -4px 0px 8px rgba(0, 0, 0, 0.1);
        z-index: 1;
        height: auto;
        width:fit-content;
        padding: 12px;
        transform: translateY(calc(65px - 75%));
        cursor: pointer;
        h3 {
            -webkit-line-clamp: 11;
            font-size: 0.6rem;
        }
    }
    & h2 {
        text-align: start;
        font-size: 0.6rem;
        overflow-y: auto;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        & img {
            width: 0.6rem;
            height: auto;
            margin-right: 0.2rem;
        };
    };
    & h3 {
        font-size: 0rem;
        font-weight: normal;
        text-align: justify;
        overflow-y: auto;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        & span {
            font-weight: bold;
        };
    };
    & h4 {
        font-size: 0.6rem;
        color: var(--black-5);
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
    }
}
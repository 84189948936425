@import '../../../styles/mixins/main.scss';

.company-data-section-alignment {
    .page-alignment {
        padding: 0 0 40px 0;
        @include flex-between;
        div {
            h1 {
                @include heading-3;
                color: var(--black-3);
                margin: 0;
            }
        }
        div + div {
            img {
                @include pointer;
            }
        }
    }
    .company-data-box-fix-width {
        max-width: 408px;
        .input {
            margin: 0 0 24px 0;
        }
        .fill-button {
            padding: 30px 0 0 0;
            display: block;
        }
        .two-col-grid {
            display: grid;
            grid-template-columns: repeat(2 , 1fr);
            gap: 0 24px;
        }
    }
}
.info {
  position: relative;
  width: 100%;
  height: 60%;

  margin-left: 16px;
  margin-right: 16px;

  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items:flex-start;
  justify-content: flex-start;
  gap: 2px;
}
.container {
  height: 2px;
  display: flex;
  cursor: pointer;
  overflow: hidden;
  &:hover { 
    height: 100%;
  }
}

.tab {
  padding: 10px 20px;
  border-bottom: 2px solid transparent;
}

.activeTab {
  border-bottom-color: blue;
}

.content {
  padding: 0;
  border: 1px solid #ccc;
}

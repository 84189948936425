@import 'src/styles/mixins/main';

.intake-keywords-section {
    padding: 128px 0 0 0;
    @include breakpoint("max-sm") {
        padding: 60px 0 0 0;
    }
    h1 {
        @include heading-3;
        color: var(--black-color);
        //max-width: 460px;
        max-width: 765px;
        margin: 0 0 map-get($pixel, md ) * 4 0;
        font-weight: 500;
    }
    .input {
        //margin: 0 0 map-get($pixel, md ) * 6 0;
        margin: 0 0 map-get($pixel, md ) * 3 0;
        textarea {
            max-width: 847px;
        }
    }
    .button-alignment-footer {
        @include flex-center;
        .fill-button {
            display: block;
            margin-right: 24px;
        }
    }
    h2 {
        @include heading-3;
        color: var(--black-color);
        margin: 0 0 map-get($pixel, md ) * 2 + 4px 0;
        font-weight: 500;
    }
    .button-alignment {
        @include flex-center;
        //padding: 0 0 map-get($pixel, md ) * 6 0;
        flex-wrap: wrap;
        max-width: 765px;
        justify-content: space-between;
        @include breakpoint("max-sm") {
            max-width: 100%;
        }
        button { 
            padding: 0px 16px;
            color: var(--black-color);
            height: 40px;
            border: 1px solid var(--primary-color);
            border-radius: 9999px;
            margin-bottom: map-get($pixel, sm ) * 2 + 2px;
            background-color: transparent;
            @include text;
            margin-right: map-get($pixel, sm ) * 2 + 2px;
            line-height:16px;
            @include pointer;
            @include flex-center;
            @include breakpoint("max-sm") {
                height: 35px;
                padding: 5px 15px;
            }
            span {
                display: block;
            }
            i {
                color: var(--primary-color);
                padding-left: 20px;
            }
        }
    }
}

html,
body {
  padding: 0;
  margin: 0;
  font-family:var(--default-font-family);
}

*,
*::after,
*::before {
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

*:focus {
  outline: 0;
  box-shadow: none;
}

h1,
h2,
h3,
h4,
h5,
p {
  margin: 0;
}

ul,
li {
  padding: 0;
  margin: 0;
  display: block;
  list-style: none;
}

a {
  color: inherit;
  text-decoration: none;
}

.bg-primary {
  background-color: var(--primary-color) !important;
}

button {
  cursor: pointer;
  font-family:var(--default-font-family);
}




.common-grid {
  display: grid;
  grid-template-columns: 280px minmax(0 , 1fr);
  gap: 24px; //between the sidebar and the results
  padding: 24px 0 24px 0;
  @include breakpoint("max-md") {
    grid-template-columns: repeat(1 , 1fr);
    gap: 0;
  } 
}
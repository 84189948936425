@import '../../../../styles/mixins/main.scss';

.container-info {
    width: 95%;
    max-width: 95%;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: start;
    align-items: start;


    gap: 48px;
    margin: 24px 8px;
    // padding: 24px;
}

.info-card{
    display: flex;
    flex-direction: column;
    justify-content: start;
    gap: 12px;
    border-radius: 8px;
    background-color: var(--bg-white);
    padding: 36px;
    //margin: 24px;
    box-shadow: 1px 1px 6px var(--bg-light-grey);
    transition: box-shadow 0.1s ease-in-out;

    h1{
        @include text-sm-bold;
        margin-bottom: 12px;
    }
}

.info-card:hover {
    box-shadow: 2px 2px 12px var(--bg-light-grey);
}
@import '../../styles/mixins/main.scss';

.new-password-box {
    width: 462px;
    .page-title {
        padding: 0 0 map-get($pixel, md ) * 4 0;
        h1 {
            @include heading-1;
            color: var(--black-color);
            margin: 0 0 24px 0;
        }
        p {
            @include text-sm;
            color: var(--black-color);
            span {
                @include font-bold;
                @include pointer;
            }
        }
    }
    .input {
        margin: 0 0 32px 0;
    }
    .request-to-login-button {
        padding: map-get($pixel, md ) * 3 0 0 0;
    }
    .back-to-login {
        padding: map-get($pixel, sm ) * 3 + 1px 0 0 0;
        a {
            @include text;
            color: var(--invert-color);
            @include text-center;
            display: block;
            @include pointer;
        }
    }
}

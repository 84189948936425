@import '../../../styles/mixins/main.scss';

.account-section-alignment {
    .page-alignment {
        padding: 0 0 40px 0;
        @include flex-between;
        div {
            h1 {
                @include heading-3;
                color: var(--black-3);
                margin: 0;
            }
        }
        div + div {
            img {
                @include pointer;
            }
        }
    }
    .account-box-fix-width {
        max-width: 408px;
    }
    .input {
        margin: 0 0 24px 0;
    }
    .fill-button {
        padding: 30px 0 0 0;
        display: block;
    }
    .tow-col-grid {
        @include grid-layout;
        grid-template-columns: repeat(2 , 1fr);
        gap: 24px;
        
    }
    .profile-grid {
        display: grid;
        align-items: center;
        margin: 0 0 46px 0;
        grid-template-columns: 100px minmax(0 , 1fr);
        gap: 27px;
        .profile-grid-items {
            .button-alignment {
                display: block;
                button {
                    display: block;
                    @include text-sm;
                    border: 1px solid var(--black-5);
                    border-radius: 8px;
                    background-color: transparent;
                    height: 40px;
                    min-width: 131px;
                    padding: 4px 10px;
                }
                button:first-child {
                    color: var(--primary-color);
                    margin-bottom: 8px;
                    border: 1px solid var(--primary-color);
                }
                button:last-child {
                    border: 1px solid var(--black-5);
                    color: var(--black-5);
                }
            }
            img {
                width: 100px;
                height: 100px;
                border-radius: 50%;
                object-fit: cover;
            }
        }
    }
}
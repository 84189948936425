@use '../mixins/breakpoint.scss';

@mixin heading-1 {
  font-size: 40px;
  line-height: 48px;
  font-family: var(--default-font-family);
  @include font-bold;
  @include breakpoint("max-sm") {
    font-size: 32px;
    line-height: 42px;
  }
}

@mixin heading-2 {
  font-size: 26px;
  line-height: 34px;
  font-family: var(--default-font-family);
  @include font-bold;
}

@mixin heading-4 {
  font-size: 20px;
  line-height: 30px;
  font-family: var(--default-font-family);
  @include font-medium;
}

@mixin heading-3 {
  font-size: 28px;
  line-height: 34px;
  font-family: var(--default-font-family);
  @include font-bold;
  @include breakpoint("max-sm")  {
    font-size: 20px;
    line-height: 30px;
  }
}


@mixin text {
  font-size: 16px;
  line-height: 19px;
  font-family: var(--default-font-family);
  @include font-normal;
}

@mixin text-bold {
  font-size: 16px;
  line-height: 19px;
  font-family: var(--default-font-family);
  @include font-bold;
}

@mixin text-md-bold {
  font-size: 18px;
  line-height: 21px;
  font-family: var(--default-font-family);
  @include font-bold;
}

@mixin text-sm {
  font-size: 14px;
  line-height: 18px;
  font-family: var(--default-font-family);
  @include font-normal;
}

@mixin text-sm-bold {
  font-size: 14px;
  line-height: 18px;
  font-family: var(--default-font-family);
  @include font-bold;
}

@mixin text-xs {
  font-size: 12px;
  line-height: 15px;
  font-family: var(--default-font-family);
  @include font-normal;
}
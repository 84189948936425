@import '../scss/transition';

.dropdown {
    position: absolute;
    @include pointer;
    width: 100%;
    .dropdown-design {
        background-color: var(--bg-white);
        @include normal-shadow;
        padding: map-get($pixel, sm ) * 2 + 2px;
        @include normal-radius;
    }
}

.dropdown-hidden {
    max-height: 0px;
    overflow: hidden;
    //@include ease-in-out;
}

.dropdown-show {
    max-height: calc(100vh - 100px);
    overflow: hidden;
    //@include ease-in-out;
}

.dropdown-scrollbar{
    overflow: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.dropdown-scrollbar::-webkit-scrollbar {
    display: none;
}

.dropdown-arrow {
    width: 12px;
    height: 12px;
    align-content: center;
    margin-bottom:4px;
}

.dropdown-arrow.rotated {
    margin-top:8px;
}
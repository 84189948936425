.follow-your-competitors-button {
    z-index: 1;
    color: #fff;
    background-color: #007bff;
    border: none;
    padding: 8px 16px;
    margin-left: 1rem;
    font-size: 16px;
    cursor: pointer;
    border-radius: 5px;
    transition: all 0.25s ease;
    font-weight: bold;
    &.hidden {
        display: none;
    }
    &:hover{
        background-color: #0056b3;
        transform: scale(1.05);
    };

}

@media (max-width: 768px) {
    .follow-your-competitors-button {
        margin-left: 4px;
        margin-right: 4px;
        font-size: 0.8rem;
    }
}

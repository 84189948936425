.call-to-action-button {
    background-color: var(--primary-color);
    opacity: 0.75;
    width: 61.8%;
    border: unset;
    border-radius: 8px;
    text-align: center;
    padding: 8px;
    font-weight: bold;
    font-size: 1rem;
    position: relative;
    z-index: 1;
    animation: delayed-rotate 15s infinite;
    transition: all 0.25s ease;
    &:hover {
        opacity: 1;
        cursor: pointer;
        color: #3b3b3b;
    }
}

@keyframes delayed-rotate {
    0%, 5% {
        transform: rotate(0deg);
    }
    6% {
        transform: rotate(-2deg);
    }
    7% {
        transform: rotate(2deg);
    }
    8% {
        transform: rotate(-2deg);
    }
    9% {
        transform: rotate(2deg);
    }
    10% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(0deg);
    }
}
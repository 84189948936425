@import '../../styles/mixins/main.scss';

.container{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-radius: 8px;
  border: 1px solid #A3E4DB;
  border-left-width: 3px;
  background-color: var(--bg-white);  
  margin-bottom: 8px;
  padding: 12px;
  width: 100%;
  transition: all 0.3s ease-out;
  overflow: hidden;
  min-width: 100%;
  //min-height: 175px;
}

.container:hover {
  box-shadow: 1px 1px 10px rgba(30, 195, 203, 0.25);
}

.pointer-on-hover:hover {
  cursor: pointer;
}

.right-container{
  width: 25%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border-left: 1px solid var(--bg-light-grey);

  .top-text{
    @include text-sm-bold;
    color: var(--black-3);
    margin-bottom: 12px;
  }

  .bottom-text{
    @include text-sm-bold;
    color: var(--bg-grey);
    margin-top: 12px;
  }

  .search-icon-on-hover{
    display: none;
    width: 30px;
    height: 30px;
    top: calc( 50% );
    right: calc( 50% - 16px );
  }
  
  .middle-text {
    display: block;
  }
  &:hover {
    .search-icon-on-hover{
      display:block;
    }
    .middle-text {
      display: none;
    }
  }
}

.left-container {
  display: flex;
  flex-direction: column;
  align-items: start;
  width: 50%;
}

.left-container .header {
  display: flex;
  justify-content: left;
  align-content: center;
  align-items: center;
  gap: 12px;
  width: 100%;
  cursor: pointer;
  margin-bottom: 12px;

  h3 {
    @include text;
    @include font-bold;
    color: var(--black-3);
    display: flex;
    align-items: center;

    img {
      width: 20px;
      height: 15px;
      margin-right: 12px;
      border-radius: 2px;
    }

    &:hover {
      color: var(--primary-light-color);
      img  {
        opacity: 0.4;
      }
    }
  }

}

.left-container .body {
  h2 {
    @include text-sm;
    @include font-bold;
    color: var(--black-3);
    margin-bottom: 12px;
  }

  div {
    @include text-sm;
    color: var(--black-3);
  }
}

@media (max-width: 600px) {
  .left-container {
    width: 100%;
    height: 90px;
    margin: 4px 0;
  }
}

@import 'src/styles/mixins/main';

.policy-header-alignment {
    @include flex-center;
    justify-content: space-between;
    padding: 0 0 36px 0;

    p {
        @include text-bold;
        color: var(--black-3);
        margin: 12px 0 12px 0;
    }

    h4{
        margin: 12px 0 12px 0;
    }

    img {
        @include pointer;
        width: 16px;
    }
}

.conditions-page-box{

    margin-left: 15vw;
    margin-right: 15vw;

    padding-top: 100px;
    padding-bottom: 100px;

    @include breakpoint("max-sm") {
        margin-left: 2vw;
        margin-right: 2vw;
        padding-top: 24px;
        padding-bottom: 24px;
    }
}

.conditions-box{
    margin-left: 10vw;
    margin-right: 10vw;
    margin-top: 50px;

    @include breakpoint("max-sm") {
        margin-left: 2vw;
        margin-right: 2vw;
        margin-top: 24px;
    }
}

.center-bottom {
    position: absolute;
    bottom: 0;
    left: 50%;
    padding-bottom: 5vh;
    margin-left: -70px; //to center the button
}

.padding-top-bottom {
    padding-top: 36px;
    padding-bottom: 24px;

    @include breakpoint("max-sm") {
        padding-top: 24px;
        padding-bottom: 12px;
    }
}

.register-box {
    width: 462px;
    .child-text-all-alignment {
        display: flex;
        p:last-child {
            padding-left: 3px;
        }
    }
    .child-product {
        label {
            @include flex-center;
        }
    }
    .input-first-grid {
        @include grid-layout;
        grid-template-columns: repeat(12 , 1fr);
        padding: 0 0 32px 0;
        gap: 24px;
        @include breakpoint("max-sm") {
            grid-template-columns: repeat(1 , 1fr);
            gap: 24px;
        }
        .input-first-grid-items {
            grid-column: span 6;
        }
    }
    .input-bottom-alignment {
        padding: 0 0 32px 0;
    }
    .register-button {
        padding: 0 0 map-get($pixel, sm ) * 3 + 1px 0;
    }
    .have-a-account {
        p {
            @include text-bold;
            color: var(--invert-color);
            @include font-normal;
            justify-content: center;
            @include flex-center;
            a {
                color: var(--primary-color);
                display: block;
                padding-left: 5px;
                @include font-bold;
                @include pointer;
            }
        }
    }
    .checkbox-text-alignment {
        @include flex-center;
        padding: 0 0 map-get($pixel, md ) * 6 0;
        div {
            @include flex-center;
            input[type="checkbox"] {
                width: 18px;
                height: 18px;
                margin: 0;
                padding: 0;
            }
            label {
                @include text-bold;
                @include flex-center;
                font-weight: 500;
                color: var(--invert-color);
                span {
                    padding-left: 9px;
                }
                a {
                    color: var(--primary-color);
                    text-decoration: underline;
                    padding-right: 3px;
                    @include pointer;
                }
            }

        }
    }
    .page-title {
        padding: 0 0 23px 0;
        h1 {
            @include heading-1;
            color: var(--black-color);
            margin: 0 0 map-get($pixel, md ) * 2 + 4px 0;
        }
        p {
            @include text-sm;
            color: var(--black-color);
            margin: 0 0 map-get($pixel, sm ) 0;
        }
     }

    .input-message {
        padding: 12px 0 0 0;
        p {
            @include text-sm;
            color: var(--red);
            margin: 0 0 map-get($pixel, sm ) 0;
        }
    }
}

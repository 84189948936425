@import '../../styles/mixins/main.scss';

$width: 5rem;
$padding: 5px;
$border: 1px solid rgb(220, 220, 220);
$border-radius: 5px;
$background: rgb(250, 250, 250);
$highlight: rgb(220, 220, 220);



.dropdown {
    width: $width;
}

.selected {
    width: $width;
    padding: $padding;
    border: $border;
    border-radius: $border-radius; 
    background-color: $background;
    transition: background-color 0.15s ease;
}

.selected:hover {
    background-color: $highlight;
    transition: background-color 0.15s ease;
}

.options {
    z-index: 2;
    position: absolute;
    top: 100%;
    width: $width;
    border: $border;
    border-radius: $border-radius;
}

.option {
    border-radius: $border-radius;
    background-color: $background;
    width: $width;
    padding: $padding;
}

.option:hover {
    background-color: $highlight;
}
.tab-panel {
    position: relative;
    display: none;
    padding: 8px 0;
    width: calc(100%);
    height: calc( 100% - 20px );
    overflow: auto;

    &.active {
        display: block;
    }
}
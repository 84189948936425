@import 'src/styles/mixins/main';

.intake-second-section {
    padding: 15vh 0 0 0;
    max-width: 462px;
    @include breakpoint("max-sm") {
        padding: 60px 0 0 0;
    }

    h1 {
        @include heading-3;
        margin: 0 0 40px 0;
        text-align: left;
        color: var(--black-3);
    }
    .government-contracts {
        span {
            @include text;
            column-rule: var(--black-5);
            display: block;
            padding: 0 0 1rem 0;
        }
    }
    .input-type-bottom-alignment {
        .input {
            margin: 0 0 24px 0;
            position: relative;
            .down-icon-alignment {
                position: absolute;
                right: 20px;
                transform: translateY(-50%);
                top: 50%;
                @include pointer;
            }
        }

        .reset-input-button{
            width: 25px;
            height: 25px;
            margin: 10px;
            padding:5px;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 360px;
        }

        .reset-input-button:hover{
            cursor: pointer;
            background-color: var(--bg-light);
        }
    }
 
   
    .button-alignment-footer {
        @include flex-center;
        padding: 30px 0 160px 0;
        .fill-button {
            display: block;
            margin-right: 24px;
        }
    }

    .matching-rules {
        h1 {
            @include text-bold;
            color: var(--black-3);
            margin: 0 0 24px 0;
        }
        .switch-text-alignment {
            padding: 0 0 30px 0;
            @include flex-center;
            justify-content: space-between;

            div {
                span {
                    @include text;
                    padding-left: 12px;
                    color: var(--black-5);
                    display: block;
                }
            }

            .switch-container {
                display: flex;
            }

            .switch {
                position: relative;
                display: inline-block;
                width: 44px;
                height: 24px;
            }

            /* Hide default HTML checkbox */
            .switch input {display:none;}

            /* The slider */
            .slider {
                position: absolute;
                cursor: pointer;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                background-color: transparent;
                border: 1px solid var(--primary-color);
                -webkit-transition: .4s;
                transition: .4s;
            }

            .slider:before {
                position: absolute;
                content: "";
                height: 18px;
                width: 18px;
                left: 4px;
                bottom: 2px;
                // background-color: white;
                background: #1EC3CB;
                -webkit-transition: .4s;
                transition: .4s;
            }

            input:checked + .slider {
                background: #1EC3CB;
                content: attr(data-on);
            }
            input:checked + .slider {
                &::before {
                    background-color: #fff;
                }
            }

            input:focus + .slider {
                box-shadow: 0 0 1px #2196F3;
            }

            input:checked + .slider:before {
                transform: translateX(18px);
            }

            /* Rounded sliders */
            .slider.round {
                border-radius: 34px;
            }

            .slider.round:before {
                border-radius: 50%;
            }
        }
    }
}
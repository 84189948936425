@import 'src/styles/mixins/main';

.sidebar-sticky {
  //position: sticky;
}

.disabled {
  opacity: 0.5;
  pointer-events: none;
}

.sidebar-design {
  background-color: var(--bg-white);
  box-shadow: var(--normal-box-shadow);
  padding: map-get($pixel, sm ) * 3 + 1px;
  border-radius: 8px;
  top: 40px;

  .filter-title {
    padding: 0 0 24px 0;

    > span {
      @include text-bold;
      color: var(--primary-color);
      display: block;
    }

    img {
      width: 16px;
      @include pointer();
    }
  }

  .relative-div {
    position: relative;
  }

  .dropdown-alignment {
    position: absolute;
    top: 78px;

    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.1);
    width: 100%;
    z-index: 9999;

    .dropdown-design {
      background-color: var(--bg-white);
      box-shadow: var(--normal-box-shadow);
      box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.1);
      border-radius: 4px;

      span:last-child {
        border: none;
      }

      span {
        display: block;
        padding: 12px;
        border-bottom: 1px solid var(--bg-light);
        @include pointer;
        @include text-sm;
      }
    }
  }

  .dropdown-title {
    padding: 0 0 16px 0;

    p {
      @include text;
      color: var(--black-3);
      font-weight: 700;
      padding: 0 0 16px 0;
    }

    .dropdown-label{
      display: flex;
      justify-content: left;
      gap: 8px;
    }

    .dropdown-container {
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      .dropdown-button {
        display: flex;
        justify-content: space-between;
        border: 1px solid var(--light-border);
        background-color: var(--bg-white);
        box-shadow: var(--normal-box-shadow);
        border-radius: 4px;
        padding: 5px 12px;
        @include text-sm;
        height: 40px;
        @include flex-between;

      }

      .pin-button{
        margin: auto;
        opacity: 0.5;
        @include pointer();
        img{
          width: 16px;
        }
      }

      .active{
        opacity: 1.0;
      }

    }
  }

  .first-menu-alignment {
    padding: 20px 0 24px 0;

    span {
      @include text-bold;
      color: var(--black-3);
      display: block;
      padding: 0 0 16px 0;
      @include pointer;
    }

    ul {
      margin: 0;
      padding: 0;

      li:last-child {
        padding: 0;
      }

      li {
        @include text-sm;
        color: var(--black-5);
        display: block;
        padding: 0 0 8px 0;
      }
    }
  }

  .top-search {
    margin: 0 0 16px 0;
    padding: 0 0 24px 0;
    border-bottom: 1px solid var(--light-border);
  }

  .input-dropdown {
    position: relative;
    margin: 0 0 16px 0;
    //border-bottom: 1px solid var(--light-border);

    .search-icon-alignment {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: 16px;
      @include flex-center;

      img {
        width: 24px;
        height: 24px;
      }
    }

    ::placeholder {
      @include text-sm;
      line-height: normal;
      vertical-align: baseline;
      color: var(--black-5);
    }

    input {
      width: 100%;
      height: 40px;
      vertical-align: baseline;
      border: none;
      background-color: var(--bg-light);
      //border-radius: 9999px;
      padding-left: 44px;
      padding-right: 20px;
      @include text-sm;
      line-height: normal;
      color: var(--black-5);
    }
  }
}

.sidebar-tab-h {
  @include breakpoint("max-md") {
    display: none;
  }
}

.radio-button-relative {
  position: relative;

  [type="radio"]:checked,
  [type="radio"]:not(:checked) {
    position: absolute;
    left: -9999px;
  }

  [type="radio"]:checked + label,
  [type="radio"]:not(:checked) + label {
    position: relative;
    padding-left: 28px;
    cursor: pointer;
    line-height: 20px;
    display: inline-block;
    color: #666;
  }

  [type="radio"]:checked + label:before,
  [type="radio"]:not(:checked) + label:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 18px;
    height: 18px;
    border: 1px solid var(--primary-color);
    border-radius: 100%;
    background: #fff;
  }

  [type="radio"]:checked + label:after,
  [type="radio"]:not(:checked) + label:after {
    content: '';
    width: 8px;
    height: 8px;
    background: var(--primary-color);
    position: absolute;
    top: 5px;
    left: 5px;
    border-radius: 100%;
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;
  }

  [type="radio"]:not(:checked) + label:after {
    opacity: 0;
    -webkit-transform: scale(0);
    transform: scale(0);
  }

  [type="radio"]:checked + label:after {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

.checkbox-button-relative {
  position: relative;

  [type="checkbox"]:checked,
  [type="checkbox"]:not(:checked) {
    position: absolute;
    left: -9999px;
  }

  [type="checkbox"]:checked + label,
  [type="checkbox"]:not(:checked) + label {
    position: relative;
    padding-left: 28px;
    cursor: pointer;
    line-height: 20px;
    display: inline-block;
    color: #666;
  }

  [type="checkbox"]:checked + label:before,
  [type="checkbox"]:not(:checked) + label:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 18px;
    height: 18px;
    border: 1px solid var(--primary-color);
    border-radius: .225rem;
    background: #fff;
  }

  [type="checkbox"]:checked + label:after,
  [type="checkbox"]:not(:checked) + label:after {
    content: '';
    width: 8px;
    height: 8px;
    background: var(--primary-color);
    position: absolute;
    top: 5px;
    left: 5px;
    //border-radius: .225rem;
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;
  }

  [type="checkbox"]:not(:checked) + label:after {
    opacity: 0;
    -webkit-transform: scale(0);
    transform: scale(0);
  }

  [type="checkbox"]:checked + label:after {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

.checkbox-label-alignment {

  p {
    @include text-bold;
    color: var(--black-3);
    margin: 0 0 1rem 0;
  }

  label {
    color: var(--black-3);
  }
}

.radio-button-relative {
  margin: 0 0 24px 0;
}

.checkbox-button-relative {
  margin: 0 0 24px 0;
}

.first-bottom-radio-alignment {
  margin: 0 0 8px 0;
}

.deleted-filter-counter {
  p {
    @include text-bold;
    color: var(--black-3);
    margin: 0;
  }
}

.date-picker {
  border-radius: 50px;
  border-width: 1px;
  line-height: 15px;
  font-size: 10px;
}

.checkbox-singleton-alignment {
  padding: 0 0 24px 0;
  @include flex-between;

  div {
    span {
      @include text;
      color: var(--black-5);
      display: block;
    }
  }

  .switch {
    position: relative;
    display: inline-block;
    width: 44px;
    height: 24px;
  }

  /* Hide default HTML checkbox */
  .switch input {
    display: none;
  }

  /* The slider */
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: transparent;
    border: 1px solid var(--primary-color);
    -webkit-transition: .4s;
    transition: .4s;
  }

  .slider:before {
    position: absolute;
    content: "";
    height: 18px;
    width: 18px;
    left: 4px;
    bottom: 2px;
    // background-color: white;
    background: #1EC3CB;
    -webkit-transition: .4s;
    transition: .4s;
  }

  input:checked + .slider {
    background: #1EC3CB;
    content: attr(data-on);
  }

  input:checked + .slider {
    &::before {
      background-color: #fff;
    }
  }

  input:focus + .slider {
    box-shadow: 0 0 1px #2196F3;
  }

  input:checked + .slider:before {
    transform: translateX(18px);
  }

  /* Rounded sliders */
  .slider.round {
    border-radius: 34px;
  }

  .slider.round:before {
    border-radius: 50%;
  }

}

.notification-bell {
  @include pointer();
  @include flex-left();

  img {
    max-width: 24px;
    margin-right: 12px;
  }

  span {
    @include text();
    line-height: 20px;
    display: inline-block;
    color: #666;
  }

}

.homebrew-date-picker-container{

  display: flex;
  gap: 8px;
  //flex-direction: column;
  justify-content: center;
  margin-bottom: 24px;

  .homebrew-date-picker{

    display: flex;
    gap: 8px;
    justify-content: center;

    .homebrew-date-picker-input-wrapper{

      border: 1px solid var(--light-border);
      border-radius: 9px;

      .homebrew-date-picker-input input{
        border: none;
        height: 32px;
        width: fit-content;
        background: none;
        text-align: center;
      }

      /* Chrome, Safari, Edge, Opera */
      input::-webkit-outer-spin-button,
      input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }

      /* Firefox */
      input[type=number] {
        -moz-appearance: textfield;
      }
    }
  }
  .homebrew-date-picker-arrow{

    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 6px 0 6px;

      img{
        width: 12px;
        height: 12px;
      }
  }
}


:root {--default-font-family:'Lato', sans-serif ;

    --primary-color:#1EC3CB;
    --secondary-color: #ff8520;
    --secondary-color-opaque: rgba(255, 133, 32, 0.5);
    --invert-color:#51565F;
    --primary-light-color:#A3E4DB;
    --black-color: #30343C;
    --bg-green: #2BA220;
    --bg-white:#fff;
    --bg-light:#F8F8F8;
    --bg-light-grey: rgb(206, 206, 206);
    --bg-grey: rgb(128, 128, 128);
    --black-5:#51565F;
    --black-3:#30343C;
    --red: #CB261E;
    --orange: #ff8520;
    --burgundy: rgb(185, 48, 60);
    --dark-green: rgba(29, 104, 21, 0.68);
    --amber: #f4bb0e;
    --pink: #ff8198;
    --gold: #ffd700;
    --olive: #808000;
    --money-green: #85bb65;

    --light-orange: #FFD580;

    --light-border:#F8F8F8;

    --text-white:#fff;

    --border-color-1:#F4F5F6;
    
    --normal-box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.15);
}
@import 'src/styles/mixins/main';

.search-filter-section-alignment {
    .first-card-details {
        background-color: var(--bg-white);
        border: 0.5px solid #F4F5F6;
        box-shadow: 2px 2px 10px rgba(9, 20, 41, 0.05);
        padding: 11px 16px 16px 16px;
        border-radius: 8px;
        //margin: 0 0 12px 0;

        p {
            @include text-sm;
            color: var(--black-5);
            margin: 0 0 13px 0;
        }

        p:last-child {
            margin: 0;
        }

        span:first-child{
            color: #1ec3cb;
            margin-right: 4px;
        }
        span:not(:first-child){
            cursor: pointer;
            //&:hover {
            //    color: var(--primary-color);
            //}
        }
    }
    .search-main-card-show {
        .red-border{
            border: 1px solid #e14949; //awards
            border-left-width: 3px;
        }
        .blue-border{
            border: 1px solid #A3E4DB; //opportunities
            border-left-width: 3px;
        }
        .opaque {
            opacity: 0.4;
        }
        .card {
            position: relative;
            border-radius: 8px;
            background-color: var(--bg-white);
            margin: 0 0 24px 0;
            @include pointer;
            padding: 11px 16px;
            @include ease-in-out;
            &:hover {
                box-shadow: 1px 1px 10px rgba(30, 195, 203, 0.25);
            }
            .overlay {
                border-radius: 8px;
                position: absolute;
                display: flex;
                justify-content: center;
                flex-direction: column;
                align-items: center;
                left: 0; top: 0; right: 0; bottom: 0;
                height: 100%;
                text-align: center;
                vertical-align: center;
                background-color: rgba(230, 230, 230, 0.9);
                z-index: 9;
                cursor: default;
                .undo {
                    color: var(--primary-color);
                    &:hover {
                        text-decoration:underline;
                        cursor: pointer;
                    }
                }
            }
            .box-body {
                .box-footer-alignment {
                    //@include flex-between;
                    display: flex;
                    justify-content: space-between;
                    padding: 6px 0 0 0;
                    cursor: default;
                    .box {
                        //flex: 1;
                        display: flex;
                        justify-content: center;
                    }

                    .box:first-child > div { margin-right: auto; }
                    .box:last-child > div { margin-left: auto;  }

                    .card-action {
                        @include flex-center;
                        @include breakpoint("max-sm") {
                            padding: 8px 0 0 0;
                        }
                        div:last-child {
                            margin: 0;
                        }

                        div {
                            width: 25px;
                            border-radius: 4px;
                            height: 25px;
                            margin-right: 4px;
                            @include flex-center;
                            justify-content: center;
                            border: 1px solid #A3E4DB;
                            @include pointer;
                            transition: .3s ease-in-out;
                            &:hover {
                                background: rgba(163, 228, 219, 0.4);
                                border: 1px solid rgba(163, 228, 219, 0.4);
                            }
                            img{
                                width: 14px;
                            }

                            @include breakpoint("max-md") {
                                width: 3.5vh;
                                height: 3.5vh;
                                img{
                                    width: 16px;
                                }

                            }

                        }

                        .selected{
                            background: rgba(163, 228, 219, 0.4);
                            border: 1px solid rgba(163, 228, 219, 0.4);
                            &:hover {
                                background: none;
                            }
                        }

                        .orange-border{
                            border: 1px solid var(--secondary-color-opaque);
                        }

                        .orange-border:hover{
                            background: var(--secondary-color-opaque);
                            border: 1px solid var(--secondary-color-opaque);
                        }

                    }
                    .labels {
                        span {
                            @include text-sm;
                            color: var(--black-3);
                        }
                    }
                    .keyword-style {
                        cursor: default;
                        margin-right: 4px;
                        p {
                            @include text-sm;
                            color: var(--primary-color);
                            span {
                                color: var(--black-5);
                            }   
                        }

                        .navigate-to-profile-button{
                            cursor: pointer;
                        }

                        .navigate-to-profile-button:hover{
                            color: var(--orange);
                        }
                    }
                }
                .awards-bottom-text {
                    padding: 6px 0 0 0;
                    @include flex-center;
                    @include breakpoint("max-sm") {
                        display: block;
                    }
                    p {
                        @include text-sm;
                        margin: 0;
                        color: var(--black-5);
                        min-width: 75px;
                    }
                    p:last-child {
                        padding-left: 60px;
                        @include breakpoint("max-sm") {
                            padding-left: 0px;
                            //padding: 1rem 0 0 0;
                        }
                    }
                }
                .show-more-result {
                    display: flex;
                    align-items: center;
                    a {
                        @include text-sm;
                        color: var(--primary-color);
                        display: block;
                        padding-right: 8px;
                    }
                }
                .search-top-space-white {
                    padding: 12px 0 0 0;
                }
                .addendum-highlights-alignment {
                    padding: 0 0 12px 0;
                    p {
                        @include text-sm-bold;
                        margin: 0 0 8px 0;
                        color: #30343C;
                        text-wrap: wrap;
                        word-wrap: anywhere;
                    }
                    span {
                        @include text-sm;
                        color: #51565F;
                        display: block;
                        cursor: default;
                    }
                }
                .title-text-padded {
                    padding: 0 0 23px 0;
                    span {
                        @include text;
                        @include font-bold;
                        color: var(--primary-color);
                        display: block;
                        padding: 0 0 16px 0;
                    }
                    p {
                        @include text-sm;
                        color: var(--black-5);
                        @include font-medium;
                    }
                }

                .title-text-border-bottom {
                    padding: 0 0 12px 0;
                    border-bottom: 1px solid #F8F8F8;
                    span {
                        @include text-sm;
                        @include font-bold;
                        color: var(--primary-color);
                        display: block;
                        padding: 0 0 16px 0;
                    }
                    p {
                        @include text-sm;
                        color: var(--black-5);
                        @include font-medium;
                    }
                }
            }
            .box-top-row-alignement {
                @include flex-between;
            }
            .box-title-alignment {
                @include flex-between;
                padding: 0 0 16px 0;
                @include breakpoint("max-sm") {
                    display: block;
                }

                .flag-section{

                    span{
                        display: flex;
                    }

                    img{
                        width: 20px;
                        height: 15px;
                        margin-right: .3125rem;
                        border-radius: 2px;
                    }
                }

                .contracting-authority-name-section{

                    text-decoration: none !important;
                    cursor: pointer !important;
                    @include text-sm;
                    color: var(--black-3) !important;
                    @include font-bold;

                    span{
                        display: flex !important; //todo find the other rules that apply and get rid of them to avoid !important
                        justify-content: space-between !important;
                        align-items: center !important;

                        span a {
                            text-decoration: none !important;
                            cursor: pointer !important;
                            @include text-sm;
                            color: var(--black-3) !important;
                            @include font-bold;
                        }

                        span a:hover{
                            color: var(--primary-color) !important;
                        }
                    }



                }

                .flag-section span:not(:first-child){
                    margin-left: 2px;
                }
                div:last-child {
                    @include breakpoint("max-sm") {
                    }
                    span {
                        @include text-xs;
                        color: var(--black-3);
                        display: block;
                        text-align: left;
                    }
                    a {
                        @include text-xs;
                        color: var(--primary-color);
                        display: block;
                        text-align: left;
                    }
                }
                div:first-child {
                    @include flex-center;
                    div {
                        @include flex-center;
                    }
                    div + div {
                        padding-left: 10px;
                        span {
                            @include text-sm-bold;
                            color: var(--black-3);
                            display: block;
                        }
                    }
                    div {
                        img {
                            @include pointer;
                        }
                    }
                }
            }
            .progress-visualization {
                margin:  0 0 16px 0;
                position: relative;
                @include flex-between;
                p {
                    @include text-sm;
                    color: var(--black-3);
                    margin: 4px 0 0 0;
                    @include font-medium;
                }
                &::before {
                    position: absolute;
                    top: 30%;
                    content: "";
                    width: 100%;
                    height: 1px;
                    border-top: 1px dashed var(--black-5);
                }
                .range-round {
                    width: 32px;
                    position: relative;
                    height: 32px;
                    border-radius: 50%;
                }
                .icon-alignment-start {
                    display: flex;
                    justify-content: flex-start;
                }
                .icon-alignment {
                    display: flex;
                    justify-content: flex-end;
                }
                .bg-green {
                    background-color: var(--bg-green);
                }
                .bg-white {
                    border: 1px solid var(--black-5);
                    background-color: #fff;
                }
                .red-round-alignment {
                    background: #DE3333;
                    width: 16px;
                    height: 16px;
                    border-radius: 50%;
                    position: absolute;
                    top: 8px;
                    right: 50%;
                }
            }
        }
    }

    .results-info-box {
        display: flex;
        justify-content: space-between;
        padding: 20px 0;

        p {
            color: #51565F;
            @include text-sm;
        }

        div + div {
            button {
                background-color: transparent;
                color: #51565F;
                border: 1px solid #A3E4DB;
                padding: 4px 12px;
                box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.1);
                border-radius: 4px;
                display: flex;
                align-items: center;
                justify-content: space-between;
                @include text-sm;
                min-width: 145px;
                height: 40px;
                font-weight: 600;
            }
        }

        .relative-div {
            position: relative;
        }

        .dropdown-alignment {
            position: absolute;
            top: 77%;

            box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.1);
            width: 100%;
            z-index: 9999;

            .dropdown-design {
                background-color: var(--bg-white);
                box-shadow: var(--normal-box-shadow);
                box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.1);
                border-radius: 4px;

                span:last-child {
                    border: none;
                }

                span {
                    color: #51565F;
                    font-weight: 600;
                    display: block;
                    padding: 12px;
                    border-bottom: 1px solid var(--bg-light);
                    @include pointer;
                    @include text-sm;
                }
            }
        }

        .dropdown-title {
            padding: 0 0 16px 0;

            p {
                @include text;
                color: var(--black-3);
                font-weight: 700;
                padding: 0 0 16px 0;
            }

            .relative-div {
                position: relative;

                button {
                    border: 1px solid var(--light-border);
                    background-color: var(--bg-white);
                    box-shadow: var(--normal-box-shadow);
                    border-radius: 4px;
                    width: 100%;
                    padding: 5px 12px;
                    @include text-sm;
                    height: 40px;
                    @include flex-between;
                }
            }
        }
    }
    .search-filter-button-alignment {
        @include flex-center;
        flex-wrap: wrap;
        margin-bottom: 12px;
        //button:last-child {
        //    margin: 0;
        //}
        button {

            //@include slide-in-left;

            cursor: default;
            border: 1px solid #A3E4DB;
            border-radius: 8px;
            height: 40px;
            padding: 4px 12px;
            color: #51565F;
            margin-right: 6px;
            margin-bottom: 6px;
            background-color: #fff;
            @include text-sm;
            @include flex-center;
            span:first-child {
                padding-right: 8px;
            }
            img{
                cursor: pointer; //remove button
                width: 10px;
            }
        }
    }
    .all-icon-text-alignment {
        @include flex-between;
        padding: 0 0 10px 0;
        @include breakpoint("max-sm") {
            display: block;
            padding: 0;
        }
        .icon-text-design {
            @include flex-center;
            @include breakpoint("max-sm") {
                padding: 0 0 10px 0;
            }
            width: 50%;
            @include breakpoint("max-sm") {
                width: 100%;
            }
            a {
                color: var(--primary-color);
                font-size: 30px;
                position: relative;
                top: -3px;
                left: 3px;
            }
            div + div {
                position: relative;
                p {
                    @include text-sm;
                    padding-left: 8px;
                    @include font-bold;
                    color: var(--black-5);
                    span {
                        @include font-medium;

                    }
                }
            }
            div:first-child {
                @include flex-center;
                img {
                    @include pointer;
                    width: 16px;
                }
            }
        }
    }
    .central-buttons-align {
        display: flex;
        justify-content: space-around;
        margin: 0px auto auto;

        .leads-align {
            @include flex-center;
            position: relative;
            justify-content: map-get($layout, center );
            span {
                display: block;
                @include text;
                @include pointer;
                color: var(--black-5);
                padding-right: 10px;
            }
            .selected{
                font-weight: bold;
            }
        }
        .leads-align-bold{

        }
    }
    .peripheral-buttons-align{
        display: flex;
        flex-direction: row;
        align-items: baseline;

        @include breakpoint("max-sm") {
            display: none;
        }

         > div{
             margin-right: 12px;
             display: flex;
             align-content: center;
         }
        img{
            width: 24px;
        }
    }
    .reset-filters-icon{
        cursor: pointer;
        height: 40px;
        padding: 4px 12px;
        //margin-left: 6px;
        margin-bottom: 6px;
        line-height: 18px;
        display: flex;
        align-items: center;
        img{
            width: 16px;
            height: 16px;
        }
    }
}

.limited-results-disclaimer {
    @include text;
    color: var(--black-3);
    background-color: var(--light-orange);
    padding: 5px;
    border: 1px solid var(--orange);
    border-radius: 4px;
    text-align: center;
    vertical-align: center;
    margin: 0 0 24px 0;
    line-height: 1.5;
    a{
        //color: var(--primary-color);
        text-decoration: underline;
    }
}

.label-button-alignment {
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    @include breakpoint("max-sm") {
        padding: 10px 0;
    }

    .animated-label{
        @include jump-up;
    }

    button {
        padding: 2px 10px;
        @include flex-center;
        font-weight: 400;
        font-size: 12px;
        line-height: 18px;
        margin: 2px;
        @include pointer;
        border-radius: 9999px;
        background-color: transparent;

        .remove-button{
            padding-left: 4px;
        }

        input{
            border: none;
            text-align: left;
            max-width: 95px;
            font-weight: 400;
            font-size: 12px;
            line-height: 18px;
        }

        input::-webkit-calendar-picker-indicator {
            display: none !important;
        }

        img {
            width: 8px;
        }
    }

    .add-label-button{
        color: var(--bg-grey);
        text-align: left;
        max-height: 20px ;
        @include text-sm;
        padding: 0px 5px 0px 5px;
        //font-weight: 400;
        //font-size: 12px;
        //line-height: 18px;
        border: none !important;
        margin-left: 4px !important;
        padding-left: 0 !important;

        //replaces layout from debounce input
        input{
            background-color: transparent;
            max-height: 20px;
            padding: 0;
            color: var(--bg-grey) !important;
        }
        img{
            display: none;
        }

        span{
            //make debounce input dropdown wider
            div:nth-child(2){
                width: auto;
                min-width: 120px;
                //padding-right:20px;
            }
            //min-width: 100px;
            span{
                flex: 1 0 100%; //avoids shrinking
                padding: 0 1% 0 3%;
            }

            span:first-child{
                padding-top: 3%;
            }

            span:last-child{
                padding-bottom: 3%;
            }

            span:hover{
                color: var(--primary-color);
            }
        }


    }
}

.pagination-top-alignment {
    padding: 20px;
    @include flex-center;
    justify-content: center;

    .main-pagination-box {
        border: 1px solid var(--black-5);
        border-radius: 4px;
        @include flex-center;

        div:last-child {
            border: none;
        }

        .current-page {
            color: var(--primary-color);
        }

        div {
            width: auto;
            padding: 5px;
            min-width: 30px;
            height: 30px;
            border-right: 1px solid var(--black-5);
            @include flex-center;
            justify-content: center;
            @include text-sm;
            color: var(--black-5);
            @include pointer;

            @include breakpoint("max-md") {
                min-width: 6vh;
                height: 6vh;
            }
        }
    }
}

.awards-modal-wrapper {
    background: rgba(48, 52, 60, 0.25);
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    @include flex-center;
    justify-content: center;
    z-index: 999;

    .awards-modal {
        width: 288px;
        margin: auto;
        border: 1px solid #F8F8F8;
        box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.1);
        border-radius: 4px;
        background-color: #ffff;
        padding: 24px;

        .fill-button {
            display: flex;
            justify-content: flex-start;

            button {
                padding: 4px 20px;
                height: 39px;
                @include text-bold;
            }
        }

        .body-text {
            padding: 0 0 23px 0;

            span {
                display: block;
                @include text-sm;
                color: #000;
            }
        }

        .header-alignment {
            @include flex-center;
            justify-content: space-between;
            padding: 0 0 24px 0;

            p {
                @include text-bold;
                color: var(--black-3);
                margin: 0;
            }

            img {
                @include pointer;
            }
        }
    }
}
.main-timeline-content-alignment {
    display: grid;
    padding: 0 0 36px 0;

    .layer1, .layer2{
        grid-column: 1;
        grid-row: 1;
    }
    .line-background, .events-box{
        grid-column: 1;
        grid-row: 1;
    }

    .line-background{
        width: calc(100% - 0px);
        height: 2px;
        position: relative;
        background: #1EC3CB;
        top: 30px;
    }

    .events-box {
        display: flex;

        .three-box {
            width: 387px;
        }

        p {
            font-weight: 500;
            font-size: 12px;
            line-height: 17px;
            text-align: center;
            color: #30343C;
            white-space: nowrap; //otherwise the lines get wonky for longer text
        }



        .center-alignment {
            display: flex;
            justify-content: center;
        }

        .line {
            width: calc(100% - 0px);
            height: 2px;
            position: relative;
            background: #1EC3CB;
            top: 30px;
        }

        .round {
            width: 28px;
            height: 28px;
            border: 1px solid #1EC3CB;
            display: flex;
            align-items: center;
            border-radius: 50%;
            justify-content: center;

            div {
                background: #1EC3CB;
                width: 21px;
                height: 21px;
                border-radius: 50%;
            }
        }

        .four-box {
            width: 156px;
        }

        .five-box {
            width: 40px;
        }

        .three-box {}

        .progress-bar-circle-box {
            width: 102px;
            position: relative;
            margin: 0 -1px 0 -1px;

            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: center;

            .progress-bar-outer-circle{

                border-radius: 50%;
                display: flex;
                justify-content: center;
                align-items: center;
                background-color: white;

                .progress-bar-inner-circle{
                    border-radius: 50%;
                }
            }

            .border-red {border: 1px solid #DE3333;}
            .border-black {border: 1px solid var(--black-3);}
            .border-orange {border: 1px solid var(--orange);}
            .border-teal{border: 1px solid var(--primary-color);}
            .border-orange-dashed {border: 1px dashed var(--orange);}
            .background-red{background-color: #DE3333;}
            .background-teal{background-color: var(--primary-color);}
            .background-orange{background-color: var(--orange);}
            .background-white{background-color: white;}
        }
    }
}

.svg-white {
    fill: #fff;
    position: relative;
}

.svg-red {
    fill: #b61717;
    position: relative;
}

.svg-orange {
    fill: var(--orange);
    position: relative;
}

.filter-results-panel{
    width: 100%;
    display: flex;
    justify-content: right;
    flex-direction: column;
    gap: 5px;

    .filter-results-buttons {

        //@include slide-in-right;

        > div {
            display: flex;
            justify-content: right;
            flex-direction: row;
        }

        p {
            color: #51565F;
            @include text-sm;
            margin-left: 2px;
        }
    }

    .filter-results-icon {
        cursor: pointer;

        > img{
            width: 20px;
        }
    }


}


@import 'src/styles/mixins/main';

.intake-second-section {
    //padding: 128px 0 0 0;
    //min-width: 100%;

    @include breakpoint("max-sm") {
        padding: 60px 0 0 0;
    }

    .company-section-alignment{
        //corrections to company.scss for signup page
        min-height: auto;
        margin: 0;
    }


    h1 {
        @include heading-3;
        margin: 0 0 40px 0;
        text-align: left;
        color: var(--black-3);
    }
    .government-contracts {
        span {
            @include text;
            column-rule: var(--black-5);
            display: block;
            padding: 0 0 1rem 0;
        }
    }
    .input-type-bottom-alignment {
        .input {
            margin: 0 0 24px 0;
            position: relative;
            .down-icon-alignment {
                position: absolute;
                right: 20px;
                transform: translateY(-50%);
                top: 50%;
                @include pointer;
            }
        }

        .reset-input-button{
            width: 25px;
            height: 25px;
            margin: 10px;
            padding:5px;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 360px;
        }

        .reset-input-button:hover{
            cursor: pointer;
            background-color: var(--bg-light);
        }
    }
 
   
    .button-alignment-footer {
        @include flex-center;
        padding: 30px 0 160px 0;
        .fill-button {
            display: block;
            margin-right: 24px;
        }
    }

}
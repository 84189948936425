.loader-wrapper {
    background: rgba(0, 0, 0, .5);
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9999;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
    align-items: center;
    -webkit-justify-content: center;
    justify-content: center;

    .loader-box{
        position: relative;
        width: 96px;
        height: 96px;
    }

    .loader-element{
        position: absolute;
        width: 96px;
        height: 96px;
        left: 0%;
        top: 0%;
        z-index: 10;

        >div>img {
            width: 100%;
            height: 100%;
        }
    }

    .spinner {
        border-radius: 50%;
        border: 6px solid;
        border-color: var(--bg-white);
        border-right-color: var(--primary-color);
        animation: spinner-d3wgkg 1.5s infinite linear;
    }

    @keyframes spinner-d3wgkg {
        to {
            transform: rotate(1turn);
        }
    }
}
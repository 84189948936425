@mixin pointer {
    cursor: map-get($layout, pointer );
}

@mixin flex-center {
    display: map-get($layout, flex );
    align-items: map-get($layout, center );
}

@mixin flex-right {
    display: map-get($layout, flex );
    justify-content: map-get($layout, right );
}

@mixin flex-left {
    display: map-get($layout, flex );
    justify-content: map-get($layout, left );
}

@mixin flex-around {
    @include flex-center();
    justify-content: space-around;
}

@mixin flex-between {
    @include flex-center();
    justify-content: space-between;
}

@mixin block {
    display: block;
}

@mixin text-center {
    text-align: map-get($layout, center );
}

@mixin grid-layout {
    display: map-get($layout, grid);
}


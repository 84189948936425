@import '../../styles/mixins/main.scss';

.search-input {
  position: relative;

  .search-icon-alignment {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 16px;
    @include flex-center;
    cursor: pointer;

    img {
      width: 16px;
      height: 16px;
    }
  }

  ::placeholder {
    @include text-sm;
    line-height: normal;
    vertical-align: baseline;
    color: var(--black-5);
  }

  input {
    width: 100%;
    height: 40px;
    vertical-align: baseline;
    border: none;
    background-color: var(--bg-light);
    border-radius: 9999px;
    padding-left: 44px;
    padding-right: 20px;
    @include text-sm;
    line-height: normal;
    color: var(--black-5);
  }
}
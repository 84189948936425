@import '../../../../styles/mixins/main.scss';

.container{

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: unset;
  width: unset;
  padding: unset;


  .top-text{
    @include text-sm-bold;
    color: var(--black-3);
    margin-bottom: 12px;
  }

  .bottom-text{
    @include text-sm;
    color: var(--bg-grey);
    margin-top: 12px;
  }

}
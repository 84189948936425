.fill-button {
    @include flex-center;
    justify-content: center;
    button {
        padding: 14px 40px;
        background-color: var(--invert-color);
        border: none;
        @include text-md-bold;
        color: var(--text-white);
        @include normal-radius;
        @include pointer;   
    }
    img{
        width: 16px;
    }
}

.outline-button {
    @include flex-center;
    justify-content: center;
    button {
        padding: 14px 40px;
        background-color: transparent;
        border: 1px solid var(--invert-color);
        @include text-md-bold;
        color: var(--invert-color);
        @include normal-radius;
        @include pointer;   
    }
}

.filter-button-style {
    display: flex;
    justify-content: flex-end;
    padding: 0 0 40px 0;
    .fill-button {
        button {
            background-color: var(--primary-color);
        }
    }
}

.disabled {
    button {
        opacity: 0.5;
        cursor: default;
    }
}

.invisible {
    button {
        opacity: 0;
        cursor: default;
    }
}

.filter-button-hidden {
    display: none;
    @include breakpoint("max-md") {
        display: block;
    }
}

.filter-sidebar-hidden {
    transform: translateX(100%);
    transition: .3s ease-in-out;
}

.filter-sidebar-show {
    transform: translateX(0%);
    transition: .3s ease-in-out;
}

.filter-sidebar {
    width: 350px;
    position: fixed;
    top: 0;
    right: 0;
    height: 100%;
    padding: 15px;
    overflow: auto;
    overflow-x: hidden;
    background-color: #fff;
    z-index: 99999999;
    .sidebar-design {
        box-shadow: none;
        border-radius: 0px;
        .filter-title {
            @include flex-between;
            i {
                color: var(--primary-color);
                @include pointer;
            }
        }
    }

}

.filter-sidebar-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 999999;
    background-color: rgba(0,0,0,0.5);
}

.files-button{
    cursor: pointer;
    color: var(--primary-color) !important;
}

.files-button-disabled{
    color: var(--primary-color) !important;
}

.cursor-pointer{
    cursor: pointer;
}

.sparkles{

    img{
        height: 16px;
        width: 16px;
        margin-left: 6px;
    }

}

.cursor-default{
    cursor: default;
}

.hover-highlight:hover{
    color: var(--primary-color);
}

.image-button {
    margin-left: 6px;
    opacity: 0.5;
    img{
        width: 12px;
    }

    &:hover {
        opacity: 1;
    }
}

.screening-result-button{

    #screening-text{
        font-size: 12px;
    }

    img {
        opacity: 0.5;
        width: 12px;
        margin-left: 2px;
    }

    img:hover {
        opacity: 1;
    }
}

.screening-result-button:hover{
    margin-left: 6px;
}

.jump-up {
    @include jump-up
}
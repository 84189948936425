@import '../../styles/mixins/main';


.checkbox-singleton-alignment {
  padding: 0 0 24px 0;
  @include flex-between;

  div {
    span {
      @include text;
      color: var(--black-5);
      display: block;
    }
  }

  .switch {
    position: relative;
    display: inline-block;
    width: 44px;
    height: 24px;
  }

  /* Hide default HTML checkbox */
  .switch input {
    display: none;
  }

  /* The slider */
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: transparent;
    border: 1px solid var(--primary-color);
    border-radius: 34px;
    -webkit-transition: .4s;
    transition: .4s;
  }

  .slider:before {
    position: absolute;
    content: "";
    height: 18px;
    width: 18px;
    left: 4px;
    bottom: 2px;
    background: #1EC3CB;
    -webkit-transition: .4s;
    transition: .4s;
    border-radius: 50%;
  }

  input:checked + .slider {
    background: #1EC3CB;
    content: attr(data-on);
  }

  input:checked + .slider {
    &::before {
      background-color: #fff;
    }
  }

  input:focus + .slider {
    box-shadow: 0 0 1px #2196F3;
  }

  input:checked + .slider:before {
    transform: translateX(18px);
  }


}

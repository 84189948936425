.recent-tender-preview{
    position: relative;
    width: calc(250px);
    height: 10rem;

    padding: 8px;

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap:4px;

    border-width: 1px;
    border-style: solid;
    border-radius: 4px;

    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);

    background-color: var(--bg-light);
    color: var(--black-3);

    overflow:auto;

    &>.contracting-authority {
        position: relative;
        width: 100%;

        font-size: 0.75rem;

        & img {
            height: 0.65em;
            width: auto;
            margin-top: 0.1rem;
            margin-right: 4px;
        }
    }

    &>.description {
        font-size: 0.65rem;
    }

    &>.publication-date {
        font-size: 0.75rem;
        align-self: end;
    }

    &>.title {
        position: relative;
        width: 100%;

        font-size: 0.75rem;
        font-weight: bold;

        & img {
            height: 0.65rem;
            width: auto;
            margin-top: 0.1rem;
            margin-right: 4px;
        }
    }
}
@import '../../styles/mixins/main.scss';

.filter-button{

  display: flex;
  justify-content: space-between;
  align-items: center;
  width: fit-content;
  gap: 8px;
  cursor: default;
  border: 1px solid #A3E4DB;
  border-radius: 8px;
  height: 40px;
  padding: 4px 12px;
  color: #51565F;
  margin-right: 6px;
  margin-bottom: 6px;
  background-color: #fff;
  font-size: 14px;
  line-height: 18px;
  font-family: var(--default-font-family);
  font-weight: 400;

  img{
    cursor: pointer;
    width: 10px;
  }
}
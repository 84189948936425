.dropdown-container {
    position: relative;
    width: 19%;

    // background-color: var(--bg-light);
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    border-color: var(--bg-grey);
    border-style: solid;
    border-width: 1px;

    border-top-width: 0px;
    
    color: var(--black-5);

    .not-active {
        background-color: rgba(255, 255, 255, 0);
        color: var(--black-3);
    }

    transition: all 0.1s ease;

    .dropdown-header{
        position: relative;
        width: 100%;
    
        background-color: var(--bg-light);
        border-bottom-left-radius: 4px;
        border-bottom-right-radius: 4px;

        color: var(--black-5);
        font-size: 1rem;
        font-weight: bold;
        max-height: 2rem;
        padding: 4px;
        text-align: center;

        cursor: pointer;
    }
    
    .dropdown-option-list {
        border-top-width: 1px;
        border-top-style: solid;
        border-top-color: var(--bg-grey);
        background-color: var(--bg-light);
        margin-top : 0px;

        padding-top: 4px;

        .dropdown-option {
            position: relative;
            width: 100%;

            text-align: center;
            font-size: 0.8rem;
            font-weight: bold;
            padding: 2px;

            border-radius: 4px;

            &:hover {
                background-color: var(--primary-color);
                cursor: pointer;
            }
        }
    }

    &.is-closed.is-active {
        border-width: 0;
        transform: translateY(0px);

        color: var(--black-3);
        font-size: 0.63rem;
        padding-top: 1px;

        .dropdown-header {
            background-color: rgba(255, 255, 255, 0);
        }

        &:hover {
            border-width: 1px;
            transform: translateY(-1px);
        }
    }

    &.is-closed.not-active {
        transform: translateY(-1px);

        &:hover {
            border-width: 1px;
        }
    }

    &.is-open {
        transform: translateY(-1px);

        .dropdown-header {
           cursor: default;
        }


    }


}
@import '../../styles/mixins/main.scss';


.popup-container {
  background: rgba(48, 52, 60, 0.25);
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  @include flex-center;
  justify-content: center;
  z-index: 999;

  .popup-modal {
    width: 288px;
    border: 1px solid #F8F8F8;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    background-color: #ffff;
    padding: 24px;

    .popup-header-alignment {
      @include flex-center;
      justify-content: space-between;
      padding: 0 0 36px 0;

      p {
        @include text-bold;
        color: var(--black-3);
        margin: 0;
      }

      img {
        @include pointer;
        width: 16px;
      }
    }

    .popup-message {
      padding: 0 0 36px 0;
      display: flex;
      justify-content: center;

      span {
        display: block;
        @include text-sm;
        color: #000;
      }
    }
    .popup-input{
      padding: 0 0 36px 0;
    }
    .valid-input{
      border-color: transparent;
    }
    .invalid-input{
      border-color: var(--red);
    }

    .popup-buttons{
      display: flex;
      justify-content: center;
      gap: 5px;
    }

    .popup-confirm-button {
      display: flex;
      justify-content: center;
      min-width: 30%;

      button {
        width: 100%;
        color: #fff;
        padding: 10px 20px;
        background: #51565F;
        border-radius: 8px;
        @include text-bold;
        @include pointer;
      }
    }


  }
}
.icon-with-text{

  display: flex;
  justify-content: left;
  align-items: center;
  gap: 8px;

  div{
    display: flex;
  }

  .icon{
    img, svg {
      width: 14px;
    }
  }

  .copy-icon{
    cursor: pointer;
    opacity: 0.6;

    img, svg {
      width: 10px;
    }
  }
}
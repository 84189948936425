@import '../../styles/mixins/main.scss';

.header-sticky {
  position: sticky;
  top: 0;
  z-index: 1000;
}

.masquerading {
  border: 3px solid var(--orange);
}

.sub-header-alignment {
  background-color: var(--bg-white);
  padding: 10px 0;
  box-shadow: var(--normal-box-shadow);

  .all-content-alignment {
    @include flex-between;
    @include breakpoint("max-md") {
      display: none;
    }

    .logo {
      //margin-left: 132px + 53px;

      img {
        @include pointer;
        max-width: 195px;
      }
    }

    .profile-hidden-dropdown {
      max-height: 0px;
      transition: .3s ease-in-out;
      overflow: hidden;
    }

    .profile-hidden-show {
      max-height: calc(100vh - 12px);
      overflow: hidden;
      transition: .3s ease-in-out;
    }

    .profile-dropdown {
      position: absolute;
      width: 160px;
      right: 0;
      top: 50px;
      padding: 0 !important;
      background-color: #fff;
      box-shadow: var(--normal-box-shadow);

      .profile-top-bottom-align {
        padding: 0 0;

        a {
          @include text-sm;
          color: var(--black-color);
          display: block;
          text-align: center;
          padding: 10px 0;
          border-bottom: 1px solid #d0d0d0;
        }
      }

    }

    .profile {
      @include flex-center;
      position: relative;
      @include breakpoint("max-md") {
        display: none;
      }
      img{
        width: 24px;
      }
      .selected {
        color: var(--primary-color);
      }
      div:first-child {
        display: flex;
        margin-right: 12px;
      }
      div + div {
        padding-left: 5px;
        display: flex;
        align-items: center;

        span {
          @include text;
        }
      }
      &:hover svg path,
      &:hover img{
        filter: invert(61%) sepia(26%) saturate(1198%) hue-rotate(134deg) brightness(100%) contrast(91%);
      }
      &:hover {
        color: var(--primary-color);
      }
    }

    .menu {
      @include flex-center;
      width: 100%;
      @include breakpoint("max-md") {
        display: none;
      }

      .menu-section{
        //this makes sure all sections are equal in length
        display: inline-flex;
        justify-content: center;
        flex:1;
      }

      .menu-logo-section{
        display: flex;
        justify-content: left;
        margin-left: 30px;
        //margin-left: 12px;
        max-width: 280px;
        flex:1;
        img {
          height: 40px;
        }

        a{
          margin: 0;
        }
      }

      div:last-of-type{
        justify-content: right;
      }

      .space-remove {
        margin: 0;
      }

      .selected {
        color: var(--primary-color);
      }

      a {
        @include text;
        color: var(--black-color);
        @include pointer;
        margin-right: 40px;
        display: block;
        text-wrap: nowrap;

        &:hover {
          color: var(--primary-color);
        }
      }
    }
  }
}

.mini-view-header {
  @include flex-center;
  justify-content: space-between;

  .logo {
    img {
      max-width: 160px;
      filter: invert(1);
    }
    svg {
      max-width: 160px;
      filter: invert(1);
    }
  }

  .all-tab-alignment {
    @include flex-center;
  }

  .tab-menu-icon {
    padding-left: 20px;

    img {
      @include pointer;
      font-size: 22px;
      color: var(--primary-color);
      width: 24px;
      //max-height: 10px;
    }

  }

  .all-tab-alignment {
    .profile {
      @include flex-center;

      div + div {
        padding-left: 1rem;
      }
    }
  }
}

.mini-view-header-show {
  display: none;
  @include breakpoint("max-md") {
    display: block;
    margin: 6px 0 6px 0;
  }
}

.mobile-sidebar-hidden {
  transform: translateX(100%);
  transition: .3s ease-in-out;
}

.mobile-sidebar-show {
  transform: translateX(0%);
  transition: .3s ease-in-out;
}

.mobile-sidebar {
  width: 350px;
  position: fixed;
  top: 0;
  right: 0;
  height: 100%;
  background-color: var(--bg-white);
  z-index: 999999;

  .mobile-body {
    padding: 40px 25px;
    overflow: scroll;
    height: 100%;

    a:first-child {
      padding-top: 0px;
    }

    a:last-child {
      border: none;
    }

    a {
      display: block;
      padding: 20px 0;
      border-bottom: 1px solid #d0d0d0;
      @include text-md-bold;
      font-size: 20px;
      color: var(--black-color);
    }

    .selected {
      color: var(--primary-color);
    }
  }

  .mobile-header {
    padding: 25px;
    @include flex-between;
    border-bottom: 1px solid #d0d0d0;

    div {
      img {
        @include pointer;
        width: 24px;
        //max-width: 150px;
      }

      i {
        font-size: 20px;
        color: var(--primary-color);
        @include pointer;
      }
    }

  }
}

.mobile-sidebar-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 999999;
  background-color: rgba(0, 0, 0, 0.5);
}

.svg-white-to-black{
  filter: invert(1);
}

.account-icon{
    width: 24px;
}


@import 'src/styles/mixins/main';

.policy-header-alignment {
    @include flex-center;
    justify-content: space-between;
    padding: 0 0 36px 0;

    p {
        @include text-bold;
        color: var(--black-3);
        margin: 0;
    }

    img {
        @include pointer;
        width: 16px;
    }
}

.padding-top {
    padding-top: 10px;
}

.tender-preview {
    margin-top: 40px;
    pointer-events: none;
    padding: 2vw;
    z-index: 0;
}

.register-box {
    @include breakpoint("max-sm") {
        width: 100%;
        height: 100%;
        opacity: 1;
        box-shadow: none;
        border-radius: 0;
    }

    .child-text-all-alignment {
        display: flex;
        text-align: justify;
        margin-bottom: 12px;
        

        @include text-bold;
        @include flex-center;
        font-weight: 500;
        color: var(--invert-color);

        p:last-child {
            padding-left: 3px;
            padding-bottom: 16px;
            font-size: 0.85rem;
            font-style: italic;
        }
    }
    .child-product {
        label {
            @include flex-center;
        }
    }
    .input-first-grid {
        @include grid-layout;
        grid-template-columns: repeat(12 , 1fr);
        padding: 0 0 16px 0;
        gap: 16px;
        @include breakpoint("max-sm") {
            grid-template-columns: repeat(1 , 1fr);
            gap: 16px;
        }
        .input-first-grid-items {
            grid-column: span 6;
        }
    }
    .input-bottom-alignment {
        padding: 0 0 16px 0;
    }
    .register-button {
        padding: 0 0 map-get($pixel, sm ) * 3 + 1px 0;
    }
    .have-a-account {
        p {
            @include text-bold;
            color: var(--invert-color);
            @include font-normal;
            justify-content: center;
            @include flex-center;
            a {
                color: var(--primary-color);
                display: block;
                padding-left: 5px;
                @include font-bold;
                @include pointer;
            }
        }
    }
    .checkbox-text-alignment {
        @include flex-center;
        padding: 0 0 24px 0;
        div {
            @include flex-center;
            input[type="checkbox"] {
                width: 18px;
                height: 18px;
                margin: 0;
                padding: 0;
            }
            label {
                @include text-bold;
                @include flex-center;
                font-weight: 500;
                color: var(--invert-color);
                span {
                    padding-left: 9px;
                }
                a {
                    color: var(--primary-color);
                    text-decoration: underline;
                    padding-right: 3px;
                    @include pointer;
                }
            }

        }
    }
    .page-title {
        padding: 0 0 23px 0;
        h1 {
            @include heading-1;
            color: var(--black-color);
            margin: 0 0 map-get($pixel, md ) * 2 + 4px 0;
        }
        p {
            @include text-sm;
            color: var(--black-color);
            margin: 0 0 map-get($pixel, sm ) 0;
        }
     }

    .input-message {
        padding: 12px 0 0 0;
        p {
            @include text-sm;
            color: var(--red);
            margin: 0 0 map-get($pixel, sm ) 0;
        }
    }
}

.card {
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    padding: 20px;
}

.preview-container {
    display: flex;
}

.preview-left {
    flex: 0 0 70%; 
}

.preview-right {
    flex: 0 0 30%;
    padding-left: 20px;
}

#selected-competitor {
    border-width: 2px;
    border-style: solid;
    border-color: var(--primary-color);
    height: 242px;
    width: 500px;
    transition: all 0.3s ease-out;
}
#selected-competitor:hover {
    cursor: unset;
    transform: none;
}
#competitor-disabled {
    opacity: 0.5;
}
#competitor-disabled:hover {
    opacity: 0.75;
}

@media (max-width: 768px)  {
    #selected-competitor {
        width: 100%;
        height: 100px;
        margin: 0;
    }
    .tender-preview {
        margin-top: -50px;
    }
}
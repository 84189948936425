/* width */
::-webkit-scrollbar {
  width: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 99999px;

}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgba(81, 86, 95, 0.8);
  border-radius: 99999px;
}

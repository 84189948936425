@import 'src/styles/mixins/main';

.flex-row-center{
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.flex-column-center{
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.intake-container {
  padding: 80px 0 0 0;
  min-width: 100%;
  max-width: 100vp;

  @include breakpoint("max-sm") {
    padding: 60px 0 0 0;
  }



  .profile-cards-row {

    display: flex;
    gap: 12px;
    margin-bottom: 12px; //margin between rows
    flex-direction: row;
    flex-basis: fit-content;
    flex-wrap: wrap;
    justify-content: center;


  }

  .profile-cards-column {
    display: flex;
    gap: 12px;
    margin-bottom: 12px;
    flex-direction: column;
    flex-basis: fit-content;
    align-items: center;
    max-height: 90%;
    overflow: scroll;
  }

  .profile-card {

    display: flex;
    border: 1px solid var(--primary-color);
    box-shadow: 2px 2px 10px rgba(9, 20, 41, 0.05);
    border-radius: 8px;
    background-color: var(--bg-white);
    padding: 20px;
    overflow-wrap: anywhere;

    div {
      @include text-sm;
      margin: auto;
    }
  }

  .selected {
    background-color: var(--orange);
  }

  .page-arrow{
    display: flex;
    border: 1px solid var(--primary-color);
    box-shadow: 2px 2px 10px rgba(9, 20, 41, 0.05);
    border-radius: 8px;
    background-color: var(--bg-white);
    padding: 10px;
    margin: 25px;
    cursor: pointer;
    //border-radius: 9px;
    //border: 1px solid var(--primary-color);
    img {
      height: 20px;
    }
  }

  .top-row {
    align-items: flex-end;
  }

  .center-row {
    align-items: center;
  }

  .bottom-row {
    align-items: flex-start;
  }


  h1 {
    @include heading-3;
    margin: 0 0 40px 0;
    text-align: left;
    color: var(--black-3);
  }

  .government-contracts {
    span {
      @include text;
      column-rule: var(--black-5);
      display: block;
      padding: 0 0 1rem 0;
    }
  }

  .input-type-bottom-alignment {
    .input {
      margin: 0 0 24px 0;
      position: relative;

      .down-icon-alignment {
        position: absolute;
        right: 20px;
        transform: translateY(-50%);
        top: 50%;
        @include pointer;
      }
    }

    .reset-input-button {
      width: 25px;
      height: 25px;
      margin: 10px;
      padding: 5px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 360px;
    }

    .reset-input-button:hover {
      cursor: pointer;
      background-color: var(--bg-light);
    }
  }


  .button-alignment-footer {
    @include flex-center;
    padding: 30px 0 160px 0;

    .fill-button {
      display: block;
      margin-right: 24px;
    }
  }

}
@forward "./breakpoint";
@forward './columns';
@forward  './typography';


.layout-main-banner {
    background-image: url("../../assets/images/layout-large-banner.png");
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    height: 100%;
    min-height: calc(100vh - 0px);
    padding: 80px 0 40px 0;
    @include breakpoint("max-xl") {
        background-image: url("../../assets/images/layout-banner.png");
    }
    @include breakpoint("max-md") {
        background-image: url("../../assets/images/tablet-layout-banner.png");
        padding: 60px 0 20px 0;
    }
    @include breakpoint("max-sm") {
        background-image: url("../../assets/images/mobile-view-banner.png");
        padding: 40px 0 0 0;
    }
}

.layout-top-alignment {
    min-height: calc(100vh - 0px);
    display: flex;
    align-items: center;
    @include breakpoint("max-md") {
        //display: flex;
        //align-items: flex-start;
        min-height: calc(100vh - 73px - 80px);
    }

}
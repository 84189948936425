@import '../../styles/mixins/main.scss';

.login-box {
    width: 462px;
    .page-title {
        padding: 0 0 map-get($pixel, md ) * 4 0;
        h1 {
            @include heading-1;
            margin: 0 0 24px 0;
            color: var(--black-color);
        }
        p {
            @include text-sm;
            color: var(--black-color);
            margin: 0;
        }
    }
    .login-button {
        padding: map-get($pixel, md ) * 3 0 0 0;
    }
    .have-a-account {
        padding: map-get($pixel, md ) + 6px 0 0 0;
        p {
            @include text-bold;
            color: var(--invert-color);
            @include font-normal;
            justify-content: center;
            @include flex-center;
            a {
                color: var(--primary-color);
                display: block;
                padding-left: 5px;
                @include font-bold;
                @include pointer;
            }
        }
    }
    .input {
        margin: 0 0 32px 0;
        a  {
            @include text-center;
            @include text;
            color: var(--invert-color);
            padding: 12px 0 0 0;
            @include pointer;
            display: block;
        }
    }
}
.button {
  padding: 4px 8px;
  border-radius: 5px;
  border-color: var(--bg-light-grey);
  border-width: 1px;
  border-style: solid;
  font-weight: bold;
  background-color: var(--bg-light);
  color: var(--black-3);
  transition: all 0.25s ease;
  box-shadow: none;
  font-size: 0.80rem;

  & span > img {
    height: 0.8rem;
    width: auto;
    margin-right: 4px;
  }
  &:hover {
    background-color: var(--bg-light-grey);
    color: var(--black-3);
    transform: scale(1.05);
  };
  &.follow{
      background-color: var(--primary-color);
      border-color: var(--primary-color);
      color: var(--bg-light);
  };
}
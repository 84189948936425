@import '../../styles/mixins/main.scss';

.login-box {
    width: 462px;
    .page-title {
        padding: 0 0 map-get($pixel, md ) * 4 0;

        h3 {
            @include heading-3;
            text-align: center;
            color: var(--black-color);

        }
        p {
            @include text-sm;
            color: var(--black-color);
            margin: 0 0 24px 0;
        }
        a{
            border-bottom: 1px solid var(--black-color);
        }
    }
    .border-span{
        display: flex;
        justify-content: center;
        span {
            width: 60%;
            //padding: 24px;
            margin: 24px;
            border-bottom: 1px solid var(--black-3);
        }
    }

}
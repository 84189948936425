@import '../../../styles/mixins/main.scss';

.keyword-section-alignment {
  .grid {
    display: flex;
    align-items: center;
    margin-bottom: 20px;

    .grid-items {
      width: 95%;
      .valid {
        border: 1px solid #51565F;
      }

      .invalid {
        border: 1px solid #e70000;
      }

      .key-input {
        border-radius: 8px;
        @include flex-between();

        input {
          width: 80%;
          height: 40px;
          border: none;
          background-color: transparent;
          padding: 0 12px;
          @include text-sm;
          color: var(--black-5);
        }

        ::placeholder {
          @include text-sm;
          color: var(--black-5);
        }

        div {
          padding: 0 12px;

          img {
            @include pointer;
            width: 16px;
            opacity: 0.3;
          }
        }
      }

      .sub-grid {
        @include grid-layout;
        grid-template-columns: repeat(5, 1fr);
        gap: 0 20px;

        .sub-grid-items {
          .button-right-alignment {
            @include flex-center;
            justify-content: center;

            img {
              @include pointer;
              width: 16px;
            }
          }

          span {
            text-align: center;
            @include text-sm;
            color: var(--black-3);
            display: block;
            font-weight: 600;
          }
        }
      }
    }
  }

  .add-input-button {
    margin-left: 12px;

    img {
      @include pointer;
      max-width: 12px;
      opacity: 0.3;
    }
  }

}

.subtitle {
  @include text-bold;
  color: var(--black-3);
  padding: 0 0 24px 0;
  display: block;
}

.language-checkboxes {

  //padding: 0 0 24px 0;

  .checkbox-button-relative {
    position: relative;

    [type="checkbox"]:checked,
    [type="checkbox"]:not(:checked) {
      position: absolute;
      left: -9999px;
    }

    [type="checkbox"]:checked + label,
    [type="checkbox"]:not(:checked) + label {
      position: relative;
      padding-left: 28px;
      cursor: pointer;
      line-height: 20px;
      display: inline-block;
      color: #666;
    }

    [type="checkbox"]:checked + label:before,
    [type="checkbox"]:not(:checked) + label:before {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      width: 18px;
      height: 18px;
      border: 1px solid var(--primary-color);
      border-radius: .225rem;
      background: #fff;
    }

    [type="checkbox"]:checked + label:after,
    [type="checkbox"]:not(:checked) + label:after {
      content: '';
      width: 8px;
      height: 8px;
      background: var(--primary-color);
      position: absolute;
      top: 5px;
      left: 5px;
      //border-radius: .225rem;
      -webkit-transition: all 0.2s ease;
      transition: all 0.2s ease;
    }

    [type="checkbox"]:not(:checked) + label:after {
      opacity: 0;
      -webkit-transform: scale(0);
      transform: scale(0);
    }

    [type="checkbox"]:checked + label:after {
      opacity: 1;
      -webkit-transform: scale(1);
      transform: scale(1);
    }
  }
}

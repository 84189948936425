@import '../../styles/mixins/main.scss';


.save-button{
  @include flex-center;
  justify-content: left;
  button {
    padding: 14px 40px;
    background-color: var(--invert-color);
    border: none;
    @include text-md-bold;
    color: var(--text-white);
    @include normal-radius;
    @include pointer;
  }

  img{
    width: 16px;
    margin-left: 10px;
  }
}
@import '../../styles/mixins/main.scss';

.specification-summary {

  margin-top: 24px;

  h1 {
    margin: 24px 0 !important;
    text-wrap: auto;
    @include text-sm;
    color: var(--black-5);
    @include font-bold;
  }

  pre {
    text-wrap: auto;
    @include text-sm;
    color: var(--black-5);
    @include font-medium;
    padding-left: 12px;
    border-left: 2px solid lightgray;
  }

  .caveat {
    @include text-sm;
    color: var(--primary-color);
    @include font-medium;
    width: 100%;
    margin: 24px 0 12px 0 !important;
    display: flex;
    justify-content: space-around;
    opacity: 0.6;
  }


}
.container {
    display: none;
    margin-bottom: 1.5rem;
    &.active {
        display: flex;
        flex-direction: row;
        justify-content: start;
        align-items: center;
    }

    width: 100%;

    .tab-list {
        display: flex;
        justify-content: start;
        align-items: center;
        flex-direction: row;

        background-color: var(--bg-white);

        border-width: 2px;
        border-style: solid;
        border-color: var(--bg-light-grey);
        border-radius: 4px;

        overflow: hidden;
    }
}
@import '../../../styles/mixins/main.scss';

.label-setting-section-alignment {

    .header-alignment {
        @include flex-center;
        justify-content: space-between;
        padding: 0 0 24px 0;

        div {
            h1 {
                @include heading-3;
                color: var(--black-3);
                margin: 0;
            }
        }
    }

    .label-setting-body-content-alignment {
        .sub-box-center-alignment {
            .center-box {

                //width: 520px;

                .add-text-style {
                    padding: 13px 0 17px 0;
                    border-bottom: 1px solid #F8F8F8;

                    img {
                        @include pointer;
                        width: 16px;
                    }
                }

                .fill-button {
                    justify-content: flex-start;
                    padding: 40px 0 0 0;

                }

                .add-tag-group {
                    padding: 28px 0 0 0;
                    display: flex;
                    justify-content: flex-start;
                    @include flex-between;

                    button {
                        color: #fff;
                        padding: 10px 20px;
                        background: #51565F;
                        border-radius: 8px;
                        @include text-bold;
                        @include pointer;
                    }

                    span {
                        display: flex;
                    }
                }

                .first-child-content-alignment {
                    @include flex-center;
                    padding: 16px 0 0 0;

                    button {
                        padding: 4px 12px;
                        background-color: transparent;
                        @include pointer;
                        border: 1px solid #51565F;
                        margin-right: 12px;
                        margin-bottom: 8px;
                        color: #51565F;
                        border-radius: 9888px;
                        @include text-sm;

                        span {
                            padding-right: 7px;
                        }
                    }
                }

                .internent-of-thing-alignment {
                    .first-header-alignment {
                        @include flex-center;
                        justify-content: space-between;
                        padding: 24px 0 0 0;

                        .text-icon-design {
                            @include flex-center;

                            span {
                                @include text;
                                color: var(--black-3);
                                display: block;
                                padding-right: 8px;
                            }

                            div {
                                width: 9px;
                                height: 9px;
                                border-radius: 9px;
                                background: #E64E3C;
                            }
                        }
                    }
                }

                .single-tags-button-alignment {
                    @include flex-center;
                    flex-wrap: wrap;

                    .label-div button {
                        background-color: transparent;
                        @include pointer;
                        padding: 4px 12px;
                        border-radius: 9999px;
                        color: #47DDB9;
                        @include text-sm;
                        border: 1px solid #47DDB9;
                        margin-right: 12px;
                        margin-bottom: 8px;
                        @include flex-center;
                        @include pointer;

                        span {
                            //padding-right: 9px;
                            @include text-sm;

                        }

                        img {
                            color: #47DDB9;
                            width: 12px;
                        }
                    }


                    .label-div .add-label-button {
                            color: var(--bg-grey);
                            text-align: left;
                            max-height: 20px ;
                            @include text-sm;
                            padding: 0px 5px 0px 5px;
                            //font-weight: 400;
                            //font-size: 12px;
                            //line-height: 18px;
                            border: none !important;
                            margin-left: 4px !important;
                            padding-left: 0 !important;

                            //replaces layout from debounce input
                            input{
                                background-color: transparent;
                                max-height: 20px;
                                padding: 0;
                                color: var(--bg-grey) !important;
                            }
                            img{
                                display: none;
                            }

                            span{
                                //make debounce input dropdown wider
                                div:nth-child(2){
                                    width: auto;
                                    min-width: 120px;
                                    //padding-right:20px;
                                }
                                //min-width: 100px;
                                span{
                                    flex: 1 0 100%; //avoids shrinking
                                    padding: 0 1% 0 3%;
                                }

                                span:first-child{
                                    padding-top: 3%;
                                }

                                span:last-child{
                                    padding-bottom: 3%;
                                }

                                span:hover{
                                    color: var(--primary-color);
                                }
                            }


                        }





                }

                .first-header-alignment {
                    @include flex-center;
                    padding: 16px 0 16px 0;
                    justify-content: space-between;

                    div {
                        img {
                            @include pointer;
                        }
                    }

                    .text-icon-alignment {
                        @include flex-center;



                        span {
                            @include text;
                            color: var(--black-3);
                            display: block;
                            padding-bottom: 0;
                            padding-right: 6px;
                            cursor: pointer;
                        }

                        .color-button {
                            width: 9px;
                            height: 9px;
                            @include pointer;
                            background: #47DDB9;
                            border-radius: 9px;
                        }
                    }

                    .icon-design{

                        span{
                            margin-left: 4px;
                        }

                        img{
                            width: 16px;
                        }
                    }
                }
            }
        }

        .text-style {
            padding: 0 0 24px 0;

            p {
                @include text;
                color: var(--black-5);
                margin: 0;

                span {
                    color: #EDAF10;
                    cursor: pointer;
                }
            }
        }
    }
}

.move-button {
    padding: 16px 0 0 0;

    button {
        padding: 10px 20px;
        background: #51565F;
        color: #fff;
        border-radius: 8px;
        @include text-bold;
        border: none;
        @include pointer;
    }
}




.modal-wrapper-filter {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9999;
    background: rgba(48, 52, 60, 0.25);
    width: 100%;
    height: 100%;
    @include flex-center;
    justify-content: center;

    .small-modal {
        width: 244px;
        background-color: var(--bg-white);
        border: 1px solid #F8F8F8;
        box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.1);
        border-radius: 4px;
        padding: 24px;


        .create-button {
            padding: 16px 0 0 0;

            button {
                padding: 0px 8px;
                min-width: 88px;
                background: #51565F;
                border-radius: 8px;
                color: #fff;
                border: none;
                height: 40px;
                @include text-bold;
            }

        }

        .modal-body {
            .color-plat {
                label {
                    @include text-sm;
                    color: var(--black-3);
                    padding: 0 0 6px 0;
                    display: block;
                }


                .color-grid {
                    display: grid;
                    grid-template-columns: repeat(4, 1fr);
                    gap: 12px;

                    .color-grid-item {
                        width: 40px;
                        @include flex-center;
                        justify-content: center;
                        height: 40px;
                        @include pointer;
                        border-radius: 2px;
                        transition: .3s ease-in-out;

                        .selected{
                            img {
                                opacity: 1;
                                transition: .3s ease-in-out;
                            }
                        }

                        img {
                            opacity: 0;
                            width: 16px;
                        }
                    }

                    .selected {
                        img {
                            opacity: 1;
                        }
                    }

                }
            }

            .input {
                padding: 0 0 16px 0;

                label {
                    @include text-sm;
                    color: var(--black-3);
                    padding: 0 0 6px 0;
                    display: block;
                }

                input {
                    height: 32px;
                    padding: 0 16px;
                }
            }

            .input-relative {
                position: relative;

                input {
                    padding: 0 14px;
                    height: 32px;
                }

                .right-side-icon-input {
                    position: absolute;
                    top: 40%;
                    transform: translateY(-50%);
                    right: 13px;

                    .center-align-image{
                        display: flex;
                        align-items: center;
                    }

                    img{
                        width: 16px;
                    }
                }
            }
        }

        .modal-header-alignment {
            @include flex-center;
            justify-content: space-between;
            padding: 0 0 17px 0;

            div {
                h4 {
                    @include text-bold;
                    color: var(--black-3);
                    margin: 0;
                }

                img {
                    @include pointer;
                    width: 16px;
                }
            }
        }
    }
}

.add-a-group-name-alignemnt {
    padding: 24px 0 0 0;

    .add-tag-input-width-fix {
        width: 408px;

        .input-new-relative {
            position: relative;

            input {
                width: 100%;
                height: 40px;
                background: #F8F8F8;
                box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.15);
                border-radius: 8px;
                @include text-sm;
                border: none;
                padding: 0 12px;
                color: var(--black-5);
            }

            .icon-alignment {
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                right: 15px;
                display: flex;
                align-items: center;
                cursor: pointer;
            }

            ::placeholder {
                @include text-sm;
                color: var(--black-5);
            }
        }

    }

    .first-header-alignment {
        @include flex-center;
        justify-content: space-between;
        padding: 24px 0 0 0;

        .text-icon-design {
            @include flex-center;

            span {
                @include text;
                color: var(--black-3);
                display: block;
                padding-right: 8px;
            }

            div {
                width: 9px;
                height: 9px;
                border-radius: 9px;
                background: #BB6CDC;
            }
        }
    }
}

.label-div{
    position: relative;

    .label-dropdown-hidden {
        max-height: 0px;
        overflow: hidden;
    }
    .label-dropdown-show {
        max-height: calc(100vh - 0px);
        overflow: hidden;
        transition: .3s ease-in-out;
    }
    .relative-alignment-label-dropdown {
        position: absolute;
        width: 155px;
        border-radius: 4px;
        z-index: 9999;

        .label-dropdown-design-alignment {
            background: #FFFFFF;
            padding: 12px;
            border: 1px solid #F8F8F8;
            box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.1);
            border-radius: 4px;
            a:first-child {
                padding-top: 0;
            }
            a:last-child {
                padding-bottom: 0px;
            }
            a {
                font-style: normal;
                font-weight: 400;
                display: block;
                padding: 12px 0;
                border-bottom: 1px solid #F8F8F8;
                font-size: 14px;
                cursor: pointer;
                line-height: 17px;
                transition: .3s ease-in-out;
                color: #51565F;
                &:hover {
                    color: #1EC3CB;
                }
            }
        }
    }
}

.reset-changes-icon{
    cursor: pointer;
    height: 40px;
    padding: 4px 12px;
    //margin-left: 6px;
    margin-bottom: 6px;
    line-height: 18px;
    display: flex;
    align-items: center;
    img{
        width: 16px;
        height: 16px;
    }
}


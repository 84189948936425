@import '../../../../styles/mixins/main.scss';

.progress-circle-container{
  width: 25%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border-left: 1px solid var(--bg-light-grey);

  .top-text{
    @include text-sm-bold;
    color: var(--black-3);
    margin-bottom: 12px;
  }

   .bottom-text{
      @include text-sm-bold;
      color: var(--bg-grey);
      margin-top: 12px;
    }

  .search-icon-on-hover{
    display: none;
    width: 30px;
    height: 30px;
    top: calc( 50% );
    right: calc( 50% - 16px );
  }
  
   .middle-text {
     display: block;
   }
   &:hover {
    .search-icon-on-hover{
       display:block;
    }
    .middle-text {
       display: none;
    }
  }
}
.pointer-on-hover:hover{
  cursor: pointer;
}
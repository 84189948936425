@import '../scss/transition';


.input {
    input, select {
        width: 100%;
        height: 50px;
        @extend .input-style;
        &:focus {
            outline: none;
            @include ease-in-out;
            border: 1px solid var(--primary-color);
        }
    }
    .input-style {
        width: 100%;
        background-color: var(--bg-light);
        padding: 0 16px;
        @include text;
        color: var(--invert-color);
        @include ease-in-out;
        @include normal-radius;
        @include font-normal;
        @include normal-shadow;
        border: 1px solid transparent;
        line-height: normal;
    }
    textarea {
        @extend .input-style;
        height: 133px;
        padding:16px;
        &:focus {
            outline: none;
            @include ease-in-out;
            border: 1px solid var(--primary-color);
        }
    }
    ::placeholder {
        @include text;
        @include font-normal;
        color: var(--invert-color);
        line-height: normal;
    }
    .input-dropdown-hidden {
        max-height: 0px;
        overflow: hidden;
        transition: .3s ease-in-out;
    }
    .input-dropdown-show {
        max-height: calc(100vh - 10px);
        overflow: hidden;
        transition: .3s ease-in-out;
    }

    .input-dropdown {
        position: absolute;
        width: 100%;
        box-shadow: 0px 1px 14px rgba(0, 0, 0, 0.15);
        border-radius: 4px;
        z-index: 99999;
    }
    .input-dropdown-design {
        background-color: var(--bg-white);
        box-shadow: 0px 1px 14px rgba(0, 0, 0, 0.15);
        border-radius: 4px;
        padding: 0 16px;
        max-width: 100%;
        width: 100%;

        span {
            display: block;
            @include text;
            @include pointer;
            color: var(--black-5);
            padding: 12px 0;
            border-bottom: 1px solid var(--border-color-1);
            max-width: 100%;
            width: 100%;
        }
    }
}
.tab-list {
  position: relative;
  z-index: 1;
  width: 100%;


  display: flex;
  flex-wrap: wrap;
  justify-content: start;
  align-items: start;

  column-gap: 1rem;

  background-color: var(--bg-white);
  
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-bottom-color: var(--bg-light-grey);
}
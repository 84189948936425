@import '../../../styles/mixins/main.scss';


.change-password-section-alignment {
    .page-alignment {
        padding: 0 0 49px 0;
        @include flex-between;
        div {
            h1 {
                @include heading-3;
                color: var(--black-3);
                margin: 0;
            }
        }
        div + div {
            img {
                @include pointer;
            }
        }
    }
    .change-password-box {
        max-width: 408px;
        .input {
            margin: 0 0 24px 0;
        }
        .fill-button {
            display: block;
            padding: 30px 0 0 0;
        }
    }
    .error {
        border-color: var(--red);
        box-shadow: none;
    }
}
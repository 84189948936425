.tender-list{
    display: flex;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: start;
    width: 100%;
    height: 100%;
    margin-top: 20px;
    gap: 4px;
}

.tender-list-title{
    align-self: center;
    margin-bottom: -20px;
    font-weight: bold;
}

@media (max-width: 768px){
    .container {
        display: none;
    }
}
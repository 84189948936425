.tab {
    position: relative;

    font-size: 1rem;
    font-weight: bold;
    max-height: 2rem;
    padding: 4px;
    color: var(--black-5);

    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;

    border-bottom-width: 2px;
    border-bottom-style: solid;
    border-bottom-color: transparent;

    transition: all 0.1s ease;

    &:hover {
        cursor: pointer;
        border-bottom-color: var(--black-5);
        color: var(--black-5);
        opacity: 0.5;

    };

    &.is-active {
        color: var(--black-3);
        border-bottom-color: var(--black-3);
        opacity: unset;
    };

}

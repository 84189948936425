.title {
  font-size: 1.2rem;
  font-weight: bold;
  color: var(--black-3);
  display: flex;
  align-items: center;
  justify-content:space-between;
  gap: 8px;
  text-align: center;

}

.title img {
  width: 1.2rem;
  height: auto;
  margin-right: 8px;
}

.follow-button{
    width: 20px;
    height: 20px;
    border-radius: 4px;
    margin-right: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: 0.3s ease-in-out;
    opacity: 0.6;
    img {
        width: 14px;
    }
    &:hover{
        transform: scale(1.1);
        transition: 100ms;
    }
}
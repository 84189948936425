.info-item{
    position: relative;
    width: 20%;
    height: 0.9rem;
    display: flex;
    flex-wrap: nowrap;
    justify-content: start;
    align-items: center;
    gap: 8px;

    font-size: 0.75rem;
    
    &.icon{
        position: relative;
        width: 50px;
        height: auto;
    }
    &.text{
        position: relative;
    }

}
.container{


    padding: 0.5rem 1rem;
    transition: all 0.1s ease;

    &:hover {
        background-color : var(--bg-light-grey);
        opacity: 0.5;
        cursor : pointer;
    }

    &.active{
        background-color : var(--bg-light-grey);
        &:hover {
            opacity: unset;
            cursor : unset;
        }

    }
}
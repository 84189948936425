@import '../../styles/mixins/main.scss';

.company-section-alignment {
    margin: 40px 0;
    min-height: calc(100vh - 171px);
    display: flex;
    flex-direction: column;
    @include breakpoint("max-sm") {
        height: auto;
    }

    .company-info-section-alignment{
        margin: 40px 0;
        display: grid;
        gap: 20px;
        grid-template-columns: 1fr 1fr;
        @include breakpoint("max-md") {
            grid-template-columns: auto;
        }

        .company-info-card {
            border: 0.5px solid var(--border-color-1);
            box-shadow: 2px 2px 10px rgba(9, 20, 41, 0.05);
            border-radius: 8px;
            background-color: var(--bg-white);
            padding: 12px 16px;
            .company-info-card-content {
                border: 1px solid var(--primary-color);
                padding: 34px;
                border-radius: 8px;
                position: relative;
                height: 100%;
                @include breakpoint("max-sm") {
                    padding: 25px 15px;

                }
                .company-info-card-title {
                    position: absolute;
                    top: -12px;
                    button {
                        @include text;
                        @include font-bold;
                        color: var(--bg-white);
                        background-color: var(--primary-color);
                        line-height: normal;
                        border-radius: 4px;
                        padding: 0px 10px;
                        height: 25px;
                        border: none;

                    }
                }
                .company-info-card-body {
                    gap: 12px;
                    display: flex;
                    flex-direction: column;
                    .sub-text-grid-border {
                        padding: 0 0 12px 0;
                        border-bottom: 1px solid var(--border-color-1);
                    }
                    .sub-text-grid-top-alignment {
                        padding: 24px 0 12px 0;
                    }
                    .sub-text-grid {
                        @include grid-layout;
                        grid-template-columns: 130px minmax(0 , 1fr);
                        gap: 0 45px;
                        padding: 0 0 12px 0;
                        @include breakpoint("max-sm") {
                            grid-template-columns: repeat(1 , 1fr);
                            gap: 10px;
                        }
                        .sub-text-grid-items {
                            label {
                                @include text-sm;
                                color: var(--black-5);
                                display: block;
                            }
                            .green-text-color {
                                color: var(--primary-color);
                            }
                            span {
                                @include text-sm;
                                color: var(--black-3);
                                display: flex;
                                flex-direction: column;
                            }
                            pre {
                                @include text-sm;
                                color: var(--black-3);
                                margin: 0 0 12px 0;
                                white-space: pre-wrap;       /* Since CSS 2.1 */
                                white-space: -moz-pre-wrap;  /* Mozilla, since 1999 */
                                white-space: pre-wrap;      /* Opera 4-6 */
                                white-space: -o-pre-wrap;    /* Opera 7 */
                                word-wrap: break-word;       /* Internet Explorer 5.5+ */
                            }
                        }
                        .sub-text-grid-sub-titles {
                            label {
                                @include text-sm;
                                color: var(--black-5);
                                display: block;
                            }
                            .green-text-color {
                                color: var(--primary-color);
                            }
                            span {
                                @include text-sm;
                                color: var(--black-3);
                                display: flex;
                                flex-direction: column;
                            }
                            pre {
                                @include text-sm;
                                color: var(--black-3);
                                text-decoration: underline;
                                margin: 0 0 0 0;
                                white-space: pre-wrap;       /* Since CSS 2.1 */
                                white-space: -moz-pre-wrap;  /* Mozilla, since 1999 */
                                white-space: pre-wrap;      /* Opera 4-6 */
                                white-space: -o-pre-wrap;    /* Opera 7 */
                                word-wrap: break-word;       /* Internet Explorer 5.5+ */
                            }
                        }
                    }

                    p {
                        max-width: 835px;
                        @include text-sm;
                        margin: 0 0 24px 0;
                        color: var(--black-3);
                    }
                    .follow-button{

                        width: 100%;
                        display: flex;
                        justify-content: end;
                        margin-bottom: -30px;
                        z-index: 100;

                        span > img{
                            width: 16px;
                            opacity: 0.5;

                            &:hover{
                                cursor: pointer;
                                opacity: 1.0;
                            }
                        }
                    }
                }

                .company-info-body-section {
                    p {
                        @include text-sm;
                        line-height: normal;
                        color: var(--black-3);
                        margin: 0 0 6px 12px;
                    }
                    h2 {
                        @include text;
                        color: var(--black-3);
                        margin: 0 0 12px 0;
                        @include font-bold;
                    }
                }

                .scroll{
                    overflow: scroll;
                    max-height: 400px;
                    scrollbar-width: none;
                }

                .columns{
                    display: grid;
                    grid-template-columns: 1fr 1fr;
                }

                .simple-details-card {
                    padding: 24px 0 0 0;
                    p:last-child {
                        margin: 0;
                    }
                    p {
                        @include text-sm;
                        color: var(--black-3);
                        margin: 0 0 12px 0;
                    }
                    pre {
                        @include text-sm;
                        color: var(--black-3);
                        margin: 0 0 12px 0;
                        white-space: pre-wrap;       /* Since CSS 2.1 */
                        white-space: -moz-pre-wrap;  /* Mozilla, since 1999 */
                        white-space: pre-wrap;      /* Opera 4-6 */
                        white-space: -o-pre-wrap;    /* Opera 7 */
                        word-wrap: break-word;       /* Internet Explorer 5.5+ */
                    }
                    h3 {
                        @include text;
                        color: var(--black-3);
                        @include font-bold;
                        margin: 0 0 1rem 0;
                    }
                }
                .file-data {
                    padding: 24px 0 0 0;
                    @include flex-around;
                    span {
                        @include text-sm;
                        display: block;
                        padding: 0 0px 0px 0;
                        color: var(--primary-color);
                        b {
                            color: var(--black-3);
                            @include font-medium;
                        }
                    }
                    a {
                        @include text-sm;
                        color: var(--primary-color);
                        @include pointer;
                        @include text-center;
                        display: block;
                    }
                    span > span{
                        display: inline;
                        padding: 0 12px 0px 0;
                    }
                    span > span > a {
                        display: inline;
                    }
                }
                .border-bottom {
                    border-bottom: 2px solid var(--border-color-1);
                    padding-bottom: 12px;
                }
                .top-section-alignment {
                    padding: 24px 0 12px 0;
                }
            }
        }

        .radio-toggle-container{

            display: flex;
            justify-content: space-between;
            div{
                display: flex;
                flex-direction: row;

                @include breakpoint("max-sm") {
                    flex-direction: column;
                }

                label {
                    display: flex;
                    flex-direction: row;
                    span {
                        @include text-sm;
                        display: flex;
                        margin: auto;
                        padding-left: 6px;
                    }
                }
            }



        }
    }

    .disclaimer {
        @include text;
        color: var(--black-3);
        background-color: var(--light-orange);
        padding: 10px 0 10px 0;
        margin: 0 0 40px 0;
        border: 1px solid var(--orange);
        border-radius: 4px;
        text-align: center;
        vertical-align: center;
    }

}


.tabs-container {
    position: relative;
    z-index: 1;

    min-height: 50vw;

    display: flex;
    flex-direction: column;
    justify-content:start;
    align-items: start;

    // background-color: var(--bg-light);
 }
.tag-list {
  position: relative;
  height: 3.4rem;
  width: 100%;

  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: start;
  align-items: start;
  gap: 8px;
}
  
  
@import '../../styles/mixins/main.scss';

.search-input {
  position: relative;

  .search-icon-alignment {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 16px;
    @include flex-center;

    img {
      width: 16px;
      height: 16px;
    }
  }

  .debounce-input::placeholder {
    @include text-sm;
    line-height: normal;
    vertical-align: baseline;
    color: var(--black-5);
  }

  .debounce-input {
    width: 100%;
    height: 40px;
    vertical-align: baseline;
    border: none;
    background-color: var(--bg-light);
    border-radius: 9999px;
    padding-left: 44px;
    padding-right: 20px;
    @include text-sm;
    line-height: normal;
    color: var(--black-5);
  }
}

.datalist{
  @include text-sm;
  background-color: var(--black-3);
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 3px 0% 0 0%;
  position: absolute;
  z-index: 2;
  border-radius: 3px;
  max-height: 160px;
  overflow-y: auto;
  overflow-x: hidden;

  .datalist-item {
    color: var(--bg-light) !important;
    flex: 1 0 100%; //avoids shrinking
    padding: 0 1% 0 3%;
    //white-space: nowrap; //remove this to show all text
    //overflow: hidden; //hides the horizontal scroll
    cursor: pointer;
  }

  .datalist-item:first-child{
    padding-top: 3%;
  }

  .datalist-item:last-child{
    padding-bottom: 3%;
  }

  .datalist-item:hover{
    color: var(--primary-color) !important;
  }
}
@import '../../styles/mixins/main.scss';

.setting-sidebar-alignment {

    @include breakpoint("max-md"){
        //don't show this in mobilbe version
        display: none;
    }

    padding-right: 24px;
    border-right: 1px solid var(--border-color-1);
    min-height: calc(100vh - 241px);
    .profile-title {
        padding: 8px 0 40px 0;
        h1 {
            @include text-md-bold;
            color: var(--black-3);
            @include text-center;
            margin: 0;
        }
    }
    .child-menu-alignment {
        .active-tab {
            background: rgba(30, 195, 203, 0.1);
            color: var(--primary-color);
        }
        ul {
            margin: 0;
            padding: 0 ;
            list-style: none;
         
            li {
                padding: 10px;
                @include text-center;
                border-radius: 8px;
                //@include ease-in-out;
                @include text-bold;
                color: var(--black-5);
                @include pointer;
                //&:hover {
                //    background: rgba(30, 195, 203, 0.1);
                //    color: var(--primary-color);
                //}
            }
        }
    }
}
@import '../../../styles/mixins/main.scss';

.plan-section-alignment {
    .page-alignment {
        padding: 0 0 40px 0;
        @include flex-between;
        div {
            h1 {
                @include heading-3;
                color: var(--black-3);
                margin: 0;
            }
        }
        div + div {
            img {
                @include pointer;
            }
        }
    }
    .plan-details-alignment {
        padding: 0 0 24px 0;
        display: flex;
        justify-content: space-between;
        flex-direction: column;

        span{
            display: flex;
            justify-content: space-between;
            flex-direction: row;
        }

        h2 {
            @include text-bold;
            color: var(--black-3);
            margin: 0 0 16px + 1px 0;
        }

        //customizing the toggle
        .checkbox-singleton-alignment{
            padding: 0 0 16px 0;
            justify-content: flex-start;
            gap: 12px;
        }

        p {
            @include text;
            color: var(--black-5);
            margin: 0;
            a {
                color: var(--primary-color);
                @include pointer;
            }
        }


    }
    .change-plan-prompt {
        padding: 24px 0 0 8px;
            @include text;
            color: var(--black-5);
            margin: 0;
            a {
                color: var(--primary-color);
                @include pointer;
            }

    }
    .plan-grid {
        display: grid;
        grid-template-columns: 292px 376px;
        gap: 24px;
        @include breakpoint("max-md") {
            grid-template-columns: repeat(1 , 1fr);
        }
        .plan-grid-items {
            .latest-payment-box {
                background-color: var(--bg-white);
                border-radius: 8px;
                padding: 16px;
                border: 1px solid var(--border-color-1);
                box-shadow: var(--normal-box-shadow);
                .box-body {
                    padding: 16px 0 0 0;
                    .all-text-alignment:last-child {
                        padding: 0;
                    }
                    .all-text-alignment {
                        padding: 0 0 20px 0;
                        @include flex-between;
                        p {
                            @include text-sm;
                            color: var(--black-5);
                            margin: 0;
                        }
                        span {
                            @include text-sm-bold;
                            color: var(--black-3);
                        }
                    }
                }
                .box-title {
                    padding: 0 0 20px 0;
                    border-bottom: 1px solid var(--border-color-1);
                    h1  {
                        @include text-bold;
                        color: var(--black-3);

                    }
                }
            }
            .plan-box {
                border: 1px solid var(--primary-color);
                padding: 16px 24px;
                border-radius: 8px;
                h3 {
                    @include heading-4;
                    margin: 0 0 17px 0;
                    @include text-center;
                    color: var(--black-3);
                }
                .icon-text-alignment {
                    display: flex;
                    margin: 0 0 6px 0;
                    div {
                        padding-left: 8px;
                        img {
                        width: 12px;
                    }

                        span {
                            @include text-sm;
                            color: var(--black-5);
                        }
                    }
                }
                .fill-button {
                    padding: 30px 0 0 0;
                    button {
                        width: 100%;
                    }
                }
                .per-month-plan {
                    padding: 0 0 24px 0;
                    h1:last-child{
                        margin: 0;
                    }
                    h1 {
                        @include heading-3;
                        color: var(--black-5);
                        margin: 0 0 5px 0;
                        @include text-center;
                        sub {
                            font-size: 16px;
                            font-weight: 400;
                            position: relative;
                            top: -5px;
                        }
                    }
                }
                .image-center-alignment {
                    padding: 0 0 24px 0;
                    @include flex-center;
                    justify-content: center;
                    img {
                        max-width: 112px;
                        height: 110px;
                    }
                }
            }
        }
    }
}
@import 'src/styles/mixins/main';

.tender-details-card-section {
    border: 0.5px solid var(--border-color-1);
    box-shadow: 2px 2px 10px rgba(9, 20, 41, 0.05);
    border-radius: 8px;
    background-color: var(--bg-white);
    padding: 12px 16px;
    .main-card {
        border: 1px solid var(--primary-color);
        padding: 34px;
        margin: 0 0 34px 0;
        border-radius: 8px;
        position: relative;
        @include breakpoint("max-sm") {
            padding: 25px 15px;

        }
        .simple-details-card {
            padding: 24px 0 0 0;
            p:last-child {
                margin: 0;
            }
            p {
                @include text-sm;
                color: var(--black-3);
                margin: 0 0 12px 0;
            }
            pre {
                @include text-sm;
                color: var(--black-3);
                margin: 0 0 12px 0;
                white-space: pre-wrap;       /* Since CSS 2.1 */
                white-space: -moz-pre-wrap;  /* Mozilla, since 1999 */
                white-space: pre-wrap;      /* Opera 4-6 */
                white-space: -o-pre-wrap;    /* Opera 7 */
                word-wrap: break-word;       /* Internet Explorer 5.5+ */
            }
            h3 {
                @include text;
                color: var(--black-3);
                @include font-bold;
                margin: 0 0 1rem 0;
            }
        }
        .file-data {
            padding: 24px 0 0 0;
            @include flex-around;
            span {
                @include text-sm;
                display: block;
                padding: 0 0px 0px 0;
                color: var(--primary-color);
                b {
                    color: var(--black-3);
                    @include font-medium;
                }
            }
            a {
                @include text-sm;
                color: var(--primary-color);
                @include pointer;
                @include text-center;
                display: block;
            }
            span > span{
                display: inline;
                padding: 0 12px 0px 0;
            }
            span > span > a {
                display: inline;
            }
        }
        .short-content-alignment {
            h2 {
                @include text;
                color: var(--black-3);
                margin: 0 0 16px 0;
                @include font-medium;
            }
            .sub-text-grid-border {
                padding: 0 0 12px 0;
                border-bottom: 1px solid var(--border-color-1);
            }
            .sub-text-grid-top-alignment {
                padding: 24px 0 12px 0;
            }
            .sub-text-grid {
                @include grid-layout;
                grid-template-columns: 130px minmax(0 , 1fr);
                gap: 0 45px;
                padding: 0 0 12px 0;
                @include breakpoint("max-sm") {
                    grid-template-columns: repeat(1 , 1fr);
                    gap: 10px;
                }
                p {
                    margin: 0 0 8px 0;
                }
                .sub-text-grid-items {
                    label {
                        @include text-sm;
                        color: var(--black-5);
                        display: block;
                    }
                    .green-text-color {
                        color: var(--primary-color);
                    }
                    span {
                        @include text-sm;
                        color: var(--black-3);
                        display: flex;
                        flex-direction: column;
                    }
                    pre {
                        @include text-sm;
                        color: var(--black-3);
                        margin: 0 0 8px 0;
                        white-space: pre-wrap;       /* Since CSS 2.1 */
                        white-space: -moz-pre-wrap;  /* Mozilla, since 1999 */
                        white-space: pre-wrap;      /* Opera 4-6 */
                        white-space: -o-pre-wrap;    /* Opera 7 */
                        word-wrap: break-word;       /* Internet Explorer 5.5+ */
                    }
                }
                .sub-text-grid-sub-titles {
                    label {
                        @include text-sm;
                        color: var(--black-5);
                        display: block;
                    }
                    .green-text-color {
                        color: var(--primary-color);
                    }
                    span {
                        @include text-sm;
                        color: var(--black-3);
                        display: flex;
                        flex-direction: column;
                    }
                    pre {
                        @include text-sm;
                        color: var(--black-3);
                        text-decoration: underline;
                        margin: 0 0 0 0;
                        white-space: pre-wrap;       /* Since CSS 2.1 */
                        white-space: -moz-pre-wrap;  /* Mozilla, since 1999 */
                        white-space: pre-wrap;      /* Opera 4-6 */
                        white-space: -o-pre-wrap;    /* Opera 7 */
                        word-wrap: break-word;       /* Internet Explorer 5.5+ */
                    }
                }
            }
            .sub-text-grid-long {
                @include grid-layout;
                grid-template-columns: 350px minmax(0 , 1fr);
                gap: 0 45px;
                @include breakpoint("max-sm") {
                    grid-template-columns: repeat(1 , 1fr);
                    gap: 10px;
                }
                p {
                    max-width: 835px;
                    @include text-sm;
                    margin: 0 0 2px 0;
                    color: var(--black-3);
                }
            }

            p {
                max-width: 835px;
                @include text-sm;
                margin: 0 0 24px 0;
                color: var(--black-3);
            }
        }
        .border-bottom {
            border-bottom: 2px solid var(--border-color-1);
            padding-bottom: 12px;
        }
        .top-section-alignment {
            padding: 24px 0 12px 0;
        }
        .text-grid {
            @include grid-layout;
            grid-template-columns: 120px minmax(0 , 1fr);
            gap: 0 24px;
            padding: 0 0 15px 0;
            @include breakpoint("max-sm") {
                grid-template-columns: repeat(1 , 1fr);
                gap: 10px;
            }
            .text-grid-items {
                label {
                    @include text-sm;
                    color: var(--black-5);
                    display: block;
                }
                .green-text-color {
                    color: var(--primary-color);
                }
                span {
                    display: block;
                    max-width: 829px;
                    @include text-sm;
                    color: var(--black-3);
                }
                pre {
                    @include text-sm;
                    color: var(--black-3);
                    margin: 0 0 12px 0;
                    white-space: pre-wrap;       /* Since CSS 2.1 */
                    white-space: -moz-pre-wrap;  /* Mozilla, since 1999 */
                    white-space: pre-wrap;      /* Opera 4-6 */
                    white-space: -o-pre-wrap;    /* Opera 7 */
                    word-wrap: break-word;       /* Internet Explorer 5.5+ */
                }
            }
        }
        .box-upper-button{
            position: absolute;
            top: -12px;
            button {
                @include text;
                @include font-bold;
                color: var(--bg-white);
                background-color: var(--primary-color);
                line-height: normal;
                border-radius: 4px;
                padding: 0px 10px;
                height: 25px;
                border: none;

            }
        }
    }
}
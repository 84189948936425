@mixin ease-in-out {
    transition: .3s ease-in-out;
}

@mixin jump-up {
    animation: jump-up-animation .3s ease-in-out forwards;
}

@keyframes jump-up-animation {
    0% {transform: translateY(0px);}
    25% {transform: translateY(-6px);}
    50% {transform: translateY(-1px);}
    50% {transform: translateY(3px);}
    100% {transform: translateY(0px);}
}

@mixin slide-in-right {
    animation: slide-in-right .3s ease-out forwards;
}

@keyframes slide-in-right {
    0% {
        transform: translateX(50px);
        opacity: 0;
    }
    100% {
        transform: translateX(0px);
        opacity: 1;
    }
}

@mixin slide-in-left {
    animation: slide-in-left .3s ease-out forwards;
}

@keyframes slide-in-left {
    0% {
        transform: translateX(-50px);
        opacity: 0;
    }
    100% {
        transform: translateX(0px);
        opacity: 1;
    }
}
@import '../../styles/mixins/main.scss';

.pagination-wrapper{
    text-align: center;
}

.pagination{
    margin: 1.25rem auto;
    display: inline-block;
    border-radius: 4px;
    border: 1px solid #e1e3e6;
    line-height: 3;
}

.pagination li.active {
    color: #282f34;
    font-weight: 700;
}

.pagination li {
    display: inline-block;
    //border-right: 1px solid #e1e3e6;
    border-bottom: 0;
    width: 2.5rem;
    font-size: 12px;
    font-size: 12/16rem;
    line-height: 1;
}

.pagination li:last-child {
    border: 0;
}

.navigation-button-active{
    @include pointer;
}

.navigation-button-inactive{
    @include pointer;
    color: gray;
}
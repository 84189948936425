.icon-with-text-and-copy-button{

  display: flex;
  justify-content: left;
  align-items: center;
  gap: 8px;

  &:hover {
    cursor: pointer;
    opacity: 0.75;
  }

  div{
    display: flex;
  }

  .icon{
    img, svg {
      width: 14px;
    }
  }

  .copy-icon{
    opacity: 0.6;

    img, svg {
      width: 10px;
    }
  }
}
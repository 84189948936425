.card-container {
    position: relative;
    top: 10rem;
    padding: 8px 0;
    margin: 24px 0 0 0;
    display: flex;
    flex-direction: column;
    align-items:flex-start;
    max-width: 90vw;

    &.fill{
        width: 100%;
        top:0;
    }
}


.competitors-container {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content:flex-start;
    flex-wrap:wrap;
    width: 100%;
}


.card-container h1 {
    position: relative;
    font-size: 1rem;
    padding: 8px;
    margin-bottom: 8px;
}


@media (max-width: 600px) {
    .card-container {
        max-width: 100%;
        padding: 16px;
        margin-top: -150px;
        box-shadow: none;
    }
   .card-container h1 {
        margin: auto;
    } 
}
@import 'src/styles/mixins/main';


.tender-details-section {
  padding: 64px 0 80px 0;

  .globally-search-alignment {
    margin: 24px 0 24px 0;
    max-width: 592px;
    position: relative;

    .icon-alignment {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: 19px;
      @include flex-center;

      img{
        width: 16px;
      }
    }

    input {
      width: 100%;
      height: 40px;
      background: #F8F8F8;
      border: none;
      box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.05);
      border-radius: 9999px;
      padding-left: 50px;
      padding-right: 10px;
    }
  }

  .grid {
    @include grid-layout;
    grid-template-columns: 
      minmax(0, 1fr) 
      auto
      auto
      ;
    @media (max-width: 720px) {
      grid-template-columns: 
        minmax(0, 1fr)
        ;
    }
    gap:  1rem;
    @include breakpoint("max-md") {
      gap: 20px;
    }
    @include breakpoint("max-sm") {
      grid-template-columns: repeat(1, 1fr);
      gap: 40px;
    }

    .grid-items {
      h1 {
        @include heading-4;
        margin: 0 0 26px 0;
        color: var(--black-3);
        .flag-section{
          img{
            width: 20px;
            height: 15px;
            margin-right: .3125rem;
            border-radius: 2px;
          }
          padding-right: 10px;
        }
      }

      .text-grid {
        @include grid-layout;
        grid-template-columns: 160px minmax(0, 1fr);
        gap: 0 47px;
        padding: 0 0 15px 0;
        @include breakpoint("max-sm") {
          grid-template-columns: repeat(1, 1fr);
          gap: 16px;
        }

        .text-grid-items {
          label {
            @include text-sm;
            color: var(--black-5);
            display: block;
          }

          p {
            @include text-sm;
            color: var(--black-3);
            margin: 0;
          }

          pre, span {
            @include text-sm;
            color: var(--black-3);
            margin: 0;
            white-space: pre-wrap; /* Since CSS 2.1 */
            white-space: -moz-pre-wrap; /* Mozilla, since 1999 */
            white-space: pre-wrap; /* Opera 4-6 */
            white-space: -o-pre-wrap; /* Opera 7 */
            word-wrap: break-word; /* Internet Explorer 5.5+ */
          }
        }

        .button-with-image{
          display: flex;
          flex-direction: row;
          gap: 6px;
          cursor: pointer;
          align-items: center;

          img {
            height: 12px;
          }
        }
      }

      .date-text-grid {
        @include grid-layout;
        grid-template-columns: 160px minmax(0, 1fr);
        gap: 0 47px;
        padding: 0 0 15px 0;

        @include breakpoint("max-sm") {
          display: flex;
          flex-direction: row;
          grid-template-columns: none;
          gap: 0px;
          grid-gap: 0px;
        }

        .date-text-item {
          @include flex-center;
          //@include pointer;

          div {
            @include flex-center;
          }

          img{
            width: 16px;
            margin-right: 11px;
          }

          span {
            @include text-sm;
            color: var(--black-3);
            @include font-medium;
            display: block;
          }

          .date-text-item-label{
            @include breakpoint("max-md") {
              display: none;
            }
          }
        }
      }
    }
  }
  .addenda-results {
    gap: 0 47px;
    padding: 0 12px 15px 16px;

    .addenda-result-item {
      p {
        @include text-sm;
        color: var(--black-3);
        margin: 0;
        padding-bottom: 10px;
        text-align: justify;
        text-justify: inter-word;
      }

      .bold {
        font-weight: 900;
      }
    }

    .padding-left {
      padding-left: 15px;
    }
  }
}
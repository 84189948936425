.tab-panel-tenders-focus {
    padding-top: 20px;
}
.activity { 
    display: flex;
    justify-content: start;
    align-items: start;
    gap: 10px;

    .cpv-icon img {
        width: 20px;
        height: auto;
    }
}